import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./NewChatMessage.module.sass";
import { callApi } from "../../../helpers";
import { ChatContext } from "../Chat";

function NewChatMessage(props) {
  const { placeholderInput, id } = props;
  const [message, setMessage] = useState("");
  const submitEnabled = message.length > 0;
  const {translations} = useContext(ChatContext);

  const onChangeMessage = (e) => {
    const newValue = e.target.value;
    setMessage(newValue);
  };

  const onSendMessageSuccess = (data) => {
    setMessage("");
    props.onSendMessage(data);
  };

  const onSendMessage = () => {
    callApi(
      "/chat_messages",
      "POST",
      "json",
      onSendMessageSuccess,
      null,
      null,
      {
        body: JSON.stringify({
          chat_message: {
            text: message,
            chat_id: id,
          },
        }),
      }
    );
  };

  const onKeyDownHandler = (e) => {
    const { code } = e;
    if (code == "Enter" && submitEnabled) {
      onSendMessage();
    }
  };

  return (
    <div className={`${style.NewChatMessage}`}>
      <input
        type={"text"}
        value={message}
        placeholder={translations.newMessagePlaceholderText}
        onChange={onChangeMessage}
        onKeyDown={onKeyDownHandler}
      />
      <button
        className={`${style.btn} ${submitEnabled ? style.btnEnabled : ""}`}
        onClick={submitEnabled ? onSendMessage : null}
      >
        {" "}
      </button>
    </div>
  );
}

NewChatMessage.propTypes = {};

NewChatMessage.defaultProps = {
  placeholderInput: "Needs translations",
};

export default NewChatMessage;
