import React from "react";
import classes from './SliderToolIcon.module.sass'
import FootPedalIcon from './assets/foot_pedal.svg'
import CycleErgometerIcon from './assets/cycle_ergometer.svg'
import ExerciseBikeIcon from './assets/exercise_bike.svg'
import HandPedalIcon from './assets/hand_pedal.svg'
import TreadmillIcon from './assets/treadmill.svg'
import WalkIcon from './assets/walk.svg'
function SliderToolIcon(props) {

  const toolCode = props.toolCode
  const tooltip = props.toolUsed
  const iconForTool = {
    "FOOT_PEDAL": FootPedalIcon,
    "CYCLE_ERGOMETER": CycleErgometerIcon,
    "EXERCISE_BIKE": ExerciseBikeIcon,
    "HAND_PEDAL": HandPedalIcon,
    "TREADMILL": TreadmillIcon,
    "WALK": WalkIcon,
  }
  return (
    <div className="row auto-tooltip" data-toggle="tooltip" title={tooltip}>
      <div className="col text-start">
        <img className={`${classes.icon}`} src={iconForTool[toolCode]}></img>
      </div>
    </div>
  )
}

export default SliderToolIcon