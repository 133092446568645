import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./LogoutIcon.module.sass";

function LogoutIcon() {
  return (
    <svg
      enableBackground="new 0 0 512 512"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      className={style.LogoutIcon}
    >
      <path d="m255.15 468.625h-191.363c-11.737 0-21.262-9.526-21.262-21.262v-382.725c0-11.737 9.526-21.262 21.262-21.262h191.363c11.758 0 21.262-9.504 21.262-21.262s-9.504-21.264-21.262-21.264h-191.363c-35.168 0-63.787 28.62-63.787 63.788v382.724c0 35.168 28.619 63.787 63.787 63.787h191.363c11.758 0 21.262-9.504 21.262-21.262s-9.504-21.262-21.262-21.262z" />
      <path d="m505.664 240.861-129.276-127.575c-8.335-8.25-21.815-8.143-30.065.213s-8.165 21.815.213 30.065l92.385 91.173h-247.559c-11.758 0-21.262 9.504-21.262 21.262s9.504 21.263 21.262 21.263h247.559l-92.385 91.173c-8.377 8.25-8.441 21.709-.213 30.065 4.167 4.21 9.653 6.336 15.139 6.336 5.401 0 10.801-2.041 14.926-6.124l129.276-127.575c4.04-3.997 6.336-9.441 6.336-15.139 0-5.696-2.275-11.118-6.336-15.137z" />
    </svg>
  );
}

LogoutIcon.propTypes = {};

LogoutIcon.defaultProps = {};

export default LogoutIcon;
