import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./SessionListContainerHorizontal.module.sass";
import { PersonalSequencePageContext } from "../PersonalSequencePage";
import { callApi } from "../../../helpers";

function SessionListContainerHorizontal(props) {
  const { state, setState, refresh } = useContext(PersonalSequencePageContext);
  const { personalSequences, rental } = state;

  const clickOnSession = (id, addId) => {
    const newState = JSON.parse(JSON.stringify(state));
    let newSelected = [...newState.newSessionForm.indexes];
    if (addId) {
      newSelected.push(id);
    } else {
      newSelected = newSelected.filter((item) => item !== id);
    }

    if (newSelected.length > 0) {
      newState.newSessionForm.state = "adding";
    } else {
      newState.newSessionForm.state = "instructions";
    }
    newState.newSessionForm.indexes = newSelected;
    setState(newState);
  };

  const getSessionItem = (id) => {
    return personalSequences.find((item) => item.id == id);
  };

  const clickOnDelete = (e, index) => {
    e.stopPropagation();
    const deleteUrl = `${rental.sessionsUrl}?personal_sequence_id=-1&sessions_indexes[]=${index}`;
    console.log(deleteUrl);
    clickOnSession(index, false);
    callApi(deleteUrl, "PATCH", "", () => {
      refresh();
    });
  };

  const renderSession = (session) => {
    const { index } = session;
    let rootClass = `${
      session.personal_sequence_id !== -1 ? style.active : ""
    } ${style.sessionLine}`;
    const sessionSelect = state.newSessionForm.indexes.includes(index);
    if (sessionSelect) {
      rootClass += ` ${style.selected}`;
    }
    if (session.done) {
      rootClass += ` ${style.noInteraction}`;
    }
    const sessionItem = getSessionItem(session.personal_sequence_id);
    const code =
      sessionItem && session.personal_sequence_id !== -1
        ? sessionItem.code
        : "";
    return (
      <div
        className={rootClass}
        onClick={() =>
          session.done ? null : clickOnSession(index, !sessionSelect)
        }
        key={`session-horizontal-${session.personal_sequence_id}-${index}}`}
      >
        <div className={`${style.sessionCounter}`}>{session.counter}</div>
        <div className={`${style.sessionVisualizer}`}>{code}</div>
        <div
          className={`${session.done ? style.done : style.deleteButton}`}
          onClick={session.done ? null : (e) => clickOnDelete(e, index)}
        />
      </div>
    );
  };

  return (
    <div className={`${style.SessionListContainerHorizontal}`}>
      <div className={`${style.sessionList}`}>
        {rental.sessions.map((item) => {
          return renderSession(item);
        })}
      </div>
    </div>
  );
}

SessionListContainerHorizontal.propTypes = {};

SessionListContainerHorizontal.defaultProps = {};

export default SessionListContainerHorizontal;
