import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classes from "./XclinicRepetitions.module.sass";

function XclinicRepetitions(props) {
  const { label, done, total } = props;

  return (
    <div className={classes.repetitions}>
      <div className={classes.label}>{label}</div>
      <div style={{ top: 10 + 'px' }} className="text-center pt-3">
        <div className={classes.done}>{done}</div>
        <span className={`mx-2 ${classes.seperator}`}></span>
        <div className={classes.total}>{total}</div>
      </div>
    </div>
  );
}

XclinicRepetitions.propTypes = {
  label: PropTypes.string,
  done: PropTypes.number,
  total: PropTypes.number,
};

XclinicRepetitions.defaultProps = {
  label: "",
  done: 0,
  total: 0,
};

export default XclinicRepetitions;
