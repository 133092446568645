import React from "react";
import style from "./RadiosBlock.module.sass";
import InfoTooltip from "../../InfoTooltip/InfoTooltip";

function RadiosBlock(props) {
  const {
    showTexts,
    values,
    labels,
    selected,
    name,
    inverted,
    title,
    message,
  } = props;

  const onChangeRadio = (e) => {
    props.onChangeRadio(e.target.value);
  };

  const renderTitle = () => {
    return (
      <div className={`${style.title}`}>
        {title}
        {message && <InfoTooltip message={message} />}
      </div>
    );
  };

  const renderLabels = () => {
    return (
      <div className={`${style.radioLabels}`}>
        {values
          ? values.map((val, index) => {
              return (
                <label key={`label_${val}`} htmlFor={val}>
                  {labels[index]}
                </label>
              );
            })
          : null}
      </div>
    );
  };

  let rootClasses = style.RadiosBlock;
  if (typeof inverted == "boolean") {
    if (inverted !== false) {
      rootClasses += " " + style.inverted;
    }
  }

  return (
    <div className={`${rootClasses}`}>
      {showTexts && renderTitle()}
      <div className={`${style.radioContainer}`}>
        {values
          ? values.map((val) => {
              return (
                <input
                  key={`radio_${val}`}
                  id={val}
                  type={"radio"}
                  name={name}
                  value={val}
                  className={`${style.radioExercise}`}
                  checked={val == selected}
                  onChange={onChangeRadio}
                />
              );
            })
          : null}
      </div>
      {showTexts && renderLabels()}
    </div>
  );
}

RadiosBlock.propTypes = {};

RadiosBlock.defaultProps = {
  showTexts: true,
  labels: [],
  selected: 3,
};

export default RadiosBlock;
