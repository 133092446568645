import React from "react";

const SequenceTypology = (props) => {
  const backgroundColor = () => {
    if(props.code == 'TYPE_BAL') {
      return '#40CBFD'
    } else if (props.code == 'TYPE_REP') {
      return '#FB5872'
    } else if (props.code == 'TYPE_ISO') {
      return '#FBCF58'
    }
  }

  return (
    <div className={`badge badge-secondary text-uppercase`}
         style={{color: backgroundColor(), backgroundColor: '#16181C'}}>
      {props.name}
    </div>
  );
};

export { SequenceTypology as default };
