import React from "react";
import ExerciseSequenceChartBalance from "./ExerciseSequence/ExerciseSequenceChart/ExerciseSequenceChartBalance";

export default (dataForGraph, group, side=null) => {
  return dataForGraph.map((data, index) => {
    let xData = [];
    let yData = [];
    if (data.results[0]?.samples[group]) {
      xData = data.results[0]?.samples[group];
    }
    if (data.results[1]?.samples[group]) {
      yData = data.results[1]?.samples[group];
    }
    let colors = data.colors[0][index]
    return (
      <ExerciseSequenceChartBalance
        title={data.title}
        xData={xData}
        yData={yData}
        xLabel={data.results[0]?.title}
        yLabel={data.results[1]?.title}
        side={side}
        borderColor1={[colors[0], colors[1]]}
      />
    );
  });
}