import React, { Fragment, useState } from "react";

import Score from "../Score/Score";
import ProgressBarContainer from "../ProgressBar/ProgressBarContainer";
import ExerciseName from "../ExerciseName/ExerciseName";
import ExerciseVideo from "../ExerciseVideo/ExerciseVideo";
import ExerciseSequences from "../ExerciseSequence/ExerciseSequences";

import classes from "./ExerciseResult.module.sass";
import Repetitions from "../Repetitions/Repetitions";
import XclinicRepetitions from "../XclinicRepetitions/XclinicRepetitions";
import XclinicDuration from "../XclinicDuration/XclinicDuration";
import KariConnectRepetitions from "../KariConnectRepetitions/KariConnectRepetitions";
import KariConnectExerciseResults from "../KariConnectExerciseResults/KariConnectExerciseResults";
import XclinicWorkAngle from "../XclinicWorkAngle/XclinicWorkAngle";
import renderBalanceCharts from "../renderBalanceCharts";

function ExerciseResult(props) {
  const {
    colors,
    dataForGraph,
    dataForScore,
    dataForSide,
    dataForVideo,
    dataSets,
    duration,
    exerciseName,
    exerciseType,
    exerciseTypology,
    isBalance,
    leftShortText,
    progressBarData,
    repetitions,
    rightShortText,
    side,
    textColor,
    type,
    workAngle
  } = props;

  const [sequencesVisibility, setSequencesVisibility] = useState(false);
  const [showChart, setShowChart] = useState(false);
  const [showChartBalance, setShowChartBalance] = useState(false);
  const [group] = useState(0);

  const handleClick = () => {
    setSequencesVisibility(!sequencesVisibility);
    if (type == 'xclinic_sequence') {
      setSequencesVisibility(false)
      toggleChart()
    }
  };

  const toggleChart = () => {
    if (isBalance) {
      setShowChartBalance(!showChartBalance);
    } else {
      setShowChart(!showChart);
    }
  }

  const renderBar = () => {
    return (
      <Fragment>
        <div className={`${classes.cell} ${classes.paddingLeft} col-sm-1 p-0`}>
          <Score {...dataForScore} textColor={textColor}/>
        </div>

        <div className={`${classes.cell} col col-sm-4`}>
          <ProgressBarContainer
            main={progressBarData.main}
            minors={progressBarData.minors}
          />
        </div>
      </Fragment>
    );
  };

  const renderXclinicBar = () => {
    return (
      <Fragment>
        <div className={`${classes.cell} ${classes.paddingLeft} col-sm-1 p-0`}>
          <Score {...dataForScore} textColor={textColor}/>
        </div>

        <div className={`${classes.cell} col col-sm-2`}>
          <ProgressBarContainer
            main={progressBarData.main}
            minors={progressBarData.minors}
          />
        </div>

        <div className={`${classes.cell} col col-sm-2`}>
          {!isBalance && <XclinicWorkAngle {...workAngle} />}
        </div>
      </Fragment>
    );
  };

  const renderKariConnectBar = () => {
    return (
      <div
        className={`${classes.cell} col col-sm-5 pt-0 d-flex justify-content-center align-items-center`}
      >
        <div className={`row`} style={{ width: "100%" }}>
          <div className={`col-1 col ${classes.sets}`}>Sets</div>
          <div className={`col`}>
            <div style={{ width: "100%" }}>
              <KariConnectExerciseResults
                colors={colors}
                rightShortText={rightShortText}
                leftShortText={leftShortText}
                exerciseGrouped={dataSets.exerciseGrouped}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderRepetitions = (type) => {
    switch (type) {
      case "daily_sequence":
        return <Repetitions {...repetitions} />;
      case "xclinic_sequence":
        return isBalance ? <XclinicDuration {...duration} /> : <XclinicRepetitions {...repetitions} />;
      default:
        return <KariConnectRepetitions
          {...repetitions}
          exerciseTypology={exerciseTypology}
        />;
    }
  };

  const renderBarByType = (type) => {
    switch (type) {
      case "daily_sequence":
        return renderBar();
      case "xclinic_sequence":
        return renderXclinicBar();
      default:
        return renderKariConnectBar();
    }
  };

  const renderExerciseSequences = () => {
    return <ExerciseResultContext.Provider value={{ dataForSide, dataSets, dataForGraph }}>
      <ExerciseSequences
        leftTranslation={dataForSide.leftTranslation}
        rightTranslation={dataForSide.rightTranslation}
        leftLegendTranslation={dataForSide.leftLegendTranslation}
        rightLegendTranslation={dataForSide.rightLegendTranslation}
        {...dataSets}
        dataForGraph={dataForGraph}
        exerciseType={exerciseType}
        side={side}
      />
    </ExerciseResultContext.Provider>
  }

  const renderExerciseCharts = () => {
    return <div>
      {showChartBalance && renderBalanceCharts(dataForGraph, group, side.code)}
      {showChart && renderBalanceCharts(dataForGraph, group, side.code)}
    </div>
  }

  return (
    <div className={`${classes.exerciseResult}`}>
      <div style={{ cursor: ["daily_sequence", "xclinic_sequence"].includes(type) ? "pointer" : "auto" }}>
        <div
          className={`${classes.exerciseName} row ms-0 me-0`}
          onClick={["daily_sequence", "xclinic_sequence"].includes(type) ? handleClick : null}
        >
          {renderBarByType(type)}
          <div
            className={`${classes.cell} col col-sm-2 border-left border-right`}
          >
            {renderRepetitions(type)}
          </div>

          <div className={`${classes.cell} col col-sm-5`}>
            <div className={`row`}>
              <div className={`col col-sm-9`}>
                <ExerciseName name={exerciseName} textColor={textColor} {...dataForSide} />
              </div>
              <div className={`col col-sm-3 ps-0`}>
                <ExerciseVideo {...dataForVideo} />
              </div>
            </div>
          </div>
        </div>
      </div>

      {sequencesVisibility ? renderExerciseSequences() : renderExerciseCharts()}
    </div>
  );
}

export const ExerciseResultContext = React.createContext({});
export default ExerciseResult;
