import React from "react";
import style from "./SummaryBlockItem.module.sass";

import CloseIcon from "./assets/cross_circle.svg";
import LinearIcon from "./assets/cross_circle.svg";
import IntervalIcon from "./assets/cross_circle.svg";

function SummaryBlockItem(props) {
  const { id, title, deleteUrl, removeItem, showClose, showIcon, iconCode } = props;

  const onClickRemove = () => {
    removeItem(deleteUrl);
  };

  console.log(props);
  const renderCloseBnt = () => {
    return (
      <div className={`${style.closeIcon}`} onClick={onClickRemove}>
        <img src={CloseIcon} />
      </div>
    );
  };

  const renderIcon = () => {
    return (
      <span>
        <img class="me-2" src={retrieveIcon()} />
      </span>
    );
  }

  const retrieveIcon = () => {
    switch (iconCode) {
      case 'linear': return LinearIcon;
      case 'interval': return IntervalIcon;
      default: return IntervalIcon;
    }
  }

  return (
    <div className={`${style.SummaryBlockItem}`}>
      <div className={`${style.name}`}>
        {showIcon && renderIcon()}
        {title}
      </div>
      {showClose && renderCloseBnt()}
    </div>
  );
}

SummaryBlockItem.propTypes = {};

SummaryBlockItem.defaultProps = {};

export default SummaryBlockItem;
