import React, { useEffect, useRef } from "react";
import SummaryBlockItem from "../SummaryBlockItem/SummaryBlockItem";
import style from "./SummuaryExercisesList.module.sass";

function SummuaryExercisesList(props) {
  const {sameDistrict, district, removeItem} = props;
  let listStyle = style.SummuaryExercisesList;
  if (!sameDistrict) {
    listStyle += " " + style.listDisable;
  }
  return (
    <ul className={`${listStyle}`}>
      {district.items.map((exercise) => {
        return (
          <li key={`district_item_${district.id}_${exercise.id}`}>
            <SummaryBlockItem
              {...exercise}
              removeItem={removeItem}
              showClose={sameDistrict}
            />
          </li>
        );
      })}
    </ul>
  );
}

SummuaryExercisesList.propTypes = {};

SummuaryExercisesList.defaultProps = {};

export default SummuaryExercisesList;
