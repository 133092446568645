import React, { useEffect, useRef, useState } from "react";
import List from "../List/List";
import NotificationPreview from "./NotificationPreview/NotificationPreview";

function NotificationsBoxPreview(props) {
  const { title, othersText, otherUrl } = props;

  const baseUrl = "/notifications.json?limit=5";
  const [queryUrl, setQueryUrl] = useState(baseUrl);

  const onRemoveItem = () => {
    refreshLists();
  };

  const refreshLists = () => {
    let newQueryUrl = baseUrl + "&random=" + Math.random();
    setQueryUrl(newQueryUrl);
  };

  const renderNotification = (item) => {
    return (
      <NotificationPreview key={item.id} {...item} onRemove={onRemoveItem} />
    );
  };

  return (
    <div>
      <div className={`border-top w-100 py-4`}>
        <strong className={`text-start text-primary`}>{title}</strong>
      </div>
      <List
        baseQuery={queryUrl}
        renderItem={renderNotification}
        showPagination={false}
      />
      <div className={`py-4 text-center text-primary`}>
        <a className={`text-primary text-decoration-none`} href={otherUrl}>{othersText}</a>
      </div>
    </div>
  );
}

NotificationsBoxPreview.propTypes = {};

NotificationsBoxPreview.defaultProps = {};

export default NotificationsBoxPreview;
