import React from "react";

const Video = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.6667 5H8.33333C6.48333 5 5 6.48333 5 8.33333V31.6667C5 32.5507 5.35119 33.3986 5.97631 34.0237C6.60143 34.6488 7.44928 35 8.33333 35H31.6667C32.5507 35 33.3986 34.6488 34.0237 34.0237C34.6488 33.3986 35 32.5507 35 31.6667V8.33333C35 6.48333 33.5 5 31.6667 5Z" fill="#FF71C7" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M25 20L16.6667 13.3333V26.6667L25 20Z" fill="white" />
    </svg>
  );
};

export default Video;
