import React, { Fragment, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./Notifications.module.sass";
import Notification from "./Notification/Notification";
import { callApi } from "../../helpers";
import List from "../List/List";

function Notifications(props) {
  const { unreadText, readText, markAsReadURL } = props;
  const rootRef = useRef();
  const baseUrlReaded = "/notifications.json?type=read&limit=15";
  const baseUrlUnreaded = "/notifications.json?type=unread&limit=30";
  const [queryUrlReaded, setQueryUrlReaded] = useState(baseUrlReaded);
  const [queryUrlUnreaded, setQueryUrlUnreaded] = useState(baseUrlUnreaded);
  const [showNotReaded, setShowNotReaded] = useState(true);
  const [notReadedAlreadyAsked, setNotReadedAlreadyAsked] = useState(false);

  useEffect(() => {
    startIntroAnimation();
  }, []);

  const markAsRead = () => {
    const idsArray = [];
    [...rootRef.current.querySelectorAll("[data-notification-id]")].map(
      (notification) => {
        const notificationId = parseInt(notification.dataset.notificationId);
        idsArray.push(notificationId);
      }
    );
    callApi(markAsReadURL, "POST", "json", null, null, null, {
      body: JSON.stringify({ notifications: idsArray }),
    });
  };

  const startIntroAnimation = () => {
    var tempTimeLine = new TimelineLite();
    tempTimeLine.staggerFrom(
      rootRef.current.querySelectorAll("li"),
      0.3,
      { opacity: 0, ease: Power4.easeOut, clearProps: "all" },
      0.1
    );
  };

  const onRemoveItem = () => {
    refreshLists();
  };

  const refreshLists = () => {
    let newQueryUrlReaded = baseUrlReaded + "&random=" + Math.random();
    let newQueryUrlUnreaded = baseUrlUnreaded + "&random=" + Math.random();
    setQueryUrlUnreaded(newQueryUrlUnreaded);
    setQueryUrlReaded(newQueryUrlReaded);
  };

  const renderItem = (item) => {
    return <Notification key={item.id} {...item} onRemove={onRemoveItem} />;
  };

  const onItemNotReadedLoaded = (items) => {
    setNotReadedAlreadyAsked(true);
    setShowNotReaded(items.length > 0);
  };

  const renderNotReaded = () => {
    return (
      <Fragment>
        <div
          className={`${style.NotificationsUnread}`}
          style={{ display: notReadedAlreadyAsked ? "block" : "none" }}
        >
          <div className={`${style.title}`}>{unreadText}</div>
          <div className={`mt-4`}>
            <List
              baseQuery={queryUrlUnreaded}
              renderItem={renderItem}
              showPagination={false}
              nothingFoundRender={() => null}
              onItemsLoaded={(items) => {
                markAsRead();
                onItemNotReadedLoaded(items);
              }}
            />
          </div>
        </div>
        <hr className={"white"} />
      </Fragment>
    );
  };

  return (
    <div className={`${style.Notifications} px-5`} ref={rootRef}>
      {showNotReaded && renderNotReaded()}
      <div className={`${style.NotificationsRead}`}>
        <div className={`${style.title}`}>{readText}</div>
        <div className={`mt-4`} style={{ opacity: 0.8 }}>
          <List
            baseQuery={queryUrlReaded}
            renderItem={renderItem}
            showPagination={false}
            onItemsLoaded={markAsRead}
          />
        </div>
      </div>
      ;
    </div>
  );
}

Notifications.propTypes = {};

Notifications.defaultProps = {};

export default Notifications;
