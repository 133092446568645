import React from "react";
import iconPain from './../Score/assets/pain.svg'

function SliderStatus(props){

  const simplified = props.simplified
  const hasPain = props.hasPain
  const hasPainTooltip = props.hasPainTooltip
  return (
    <div>
      { hasPain &&
        <img className="auto-tooltip pointer-events-auto" src={iconPain}
              data-toggle="tooltip"
              style={{position: 'relative', top: '-2px'}}
              title={hasPainTooltip}/>}
      {
        simplified == 1 &&
        <div className="simplified pointer-events-auto auto-tooltip"
              data-toggle="tooltip"
              title={simplifiedTooltip}/>
      }
    </div>
  )
}

export default SliderStatus