import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./ExercisesBar.module.sass";

function ExercisesBar(props) {
  const {
    exercises,
    label,
    showPain,
    noBorder,
    backgroundColor,
    barCompletedColor,
    barCompletedColorLight
  } = props;
  const currentClass = (exercise) => {
    if(exercise.state == 'interrupted'){
      if (exercise.pain_value) {
        return style.pain;
      } else {
        return style.skipped;
      }
    }
    else if (exercise.pain_value) {
      return style.pain;
    } else {
      return exercise.state ? style[exercise.state] : "";
    }
  };

  const backgroundColorStyle = (exercise) => {
    if(exercise.pain_value) {
      return '#FF1451'
    } else if(exercise.state == 'interrupted') {
      return '#226A89'
    } else if(exercise.state) {
      return backgroundColor
    }
    return ''
  }

  const backgroundStyle = (exercise) => {
    if(exercise.pain_value) {
      return 'linear-gradient(180deg, #FF1451 0%, rgba(255, 20, 81, 0.5) 100%)'
    } else if(exercise.state == 'interrupted') {
      return 'linear-gradient(180deg, #226A89 0%, #234D62 100%)'
    } else if(exercise.state) {
      return `linear-gradient(180deg, ${barCompletedColor} 0%, ${barCompletedColorLight} 100%)`
    }
  }

  const pain = (exercise) => {
    return exercise.pain_value > 0 && showPain ? exercise.pain_value : "";
  };

  let rootClass = `${style.ExercisesBar}`;
  if (noBorder) {
    rootClass += ` ${style["ExercisesBar--noBorder"]}`;
  }

  return (
    <div className={rootClass}>
      {exercises.map((exercise, index) => {
        return (
          <div
            key={`${label}-${index}-${exercise.id}`}>
            <div
              style={{backgroundColor: backgroundColorStyle(exercise), background: backgroundStyle(exercise)}}>
              <div className={style.painLabel}>{pain(exercise)}</div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

ExercisesBar.propTypes = {
  exercises: PropTypes.array,
};

ExercisesBar.defaultProps = {
  showPain: false,
  noBorder: false,
  exercises: [
    "complete",
    "complete",
    "pain",
    "complete",
    "complete",
    "pain",
    "not_complete",
    "pain",
    "complete",
    "complete",
    "pain",
    "not_complete",
    "complete",
    "complete",
    "pain",
    "complete",
    "complete",
    "pain",
    "not_complete",
    "pain",
    "complete",
    "complete",
    "pain",
    "not_complete",
  ],
};

export default ExercisesBar;
