import React, { useEffect, useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import style from "./LanguageChanger.module.sass";
import { callApi } from "../../../../helpers";
import { SettingsContext } from "../../Settings";

function LanguageChanger(props) {
  const {} = props;
  const [showBtn, setShowBtn] = useState(false);
  const value = useContext(SettingsContext);
  const languages = value.sections.languages;
  const [lang, setLang] = useState(languages.value);
  const saveSetting = value.saveSetting;
  const translations = value.translations;

  const onChangeSelect = (e, setting) => {
    setLang(e.target.value);
    setShowBtn(true);
    saveSetting(languages.url, {
      body: JSON.stringify({
        lang: e.target.value,
      }),
    });
  };

  return (
    <div className={`${style.LanguageChanger}`}>
      <h2 className={`mb-3`}>{translations.settings.languages.title}</h2>
      <span data-type="select">
        <select value={lang} onChange={(e) => onChangeSelect(e, "language")}>
          <option value="it">🇮🇹 Italiano</option>
          <option value="de">🇩🇪 Deutsch</option>
          <option value="en-US">🇺🇸 English</option>
          <option value="fr">🇫🇷 Français</option>
        </select>
      </span>

      <span className={`${style.refreshBlock} ${showBtn ? style['refreshBlock--show'] : ''} `}>
        <a href={languages.urlRefresh}>
          <button>{translations.settings.languages.refresh}</button>
        </a>
      </span>
    </div>
  );
}

LanguageChanger.propTypes = {};

LanguageChanger.defaultProps = {
  lang: "it",
};

export default LanguageChanger;
