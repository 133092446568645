import React from "react";

const ManageTemplate = (props) => {
  return (
    <svg
      height="23"
      viewBox="0 0 24 23"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" fillRule="evenodd" stroke="currentColor" strokeWidth="1.5">
        <g strokeLinecap="round">
          <path d="m10 2.5h13" />
          <path d="m10 10.5h13" />
          <path d="m10 18.5h13" />
          <path d="m10 5.5h8" />
          <path d="m10 13.5h8" />
          <path d="m10 21.5h8" />
        </g>
        <rect height="5.5" rx="2" width="5.5" x=".75" y=".75" />
        <rect height="5.5" rx="2" width="5.5" x=".75" y="8.75" />
        <rect height="5.5" rx="2" width="5.5" x=".75" y="16.75" />
      </g>
    </svg>
  );
};

export default ManageTemplate;
