import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./MainNavbar.module.sass";
import LogoutIcon from "./LogoutIcon/LogoutIcon";
import ModalUpdates from "../ModalUpdates/ModalUpdates";
import { callApi } from "../../helpers";

function MainNavbar(props) {
  const {
    translations,
    avatar,
    defaultAvatar,
    logoutURL,
    tooltipLogout,
    tooltipProfile,
    profileURL,
    dev,
    devURL,
  } = props;

  const [triggerButtons, setTriggerButtons] = useState([]);

  useEffect(() => {
    setTriggerButtons(["#cliccaQui"]);
  }, []);

  const onClickDev = (e) => {
    e.preventDefault();
    callApi(devURL, "POST", "json");
  };

  return (
    <nav className={`${style.MainNavbar}`}>
      <div id="uber-title" className={`${style.title}`}>
        {translations.title}
      </div>
      <div className={`${style.rightButtons}`}>
        <a
          href={`#`}
          className={`fw-bold text-white text-decoration-none`}
          id={"cliccaQui"}
        >
          {translations.whatsNew}
        </a>
        {dev && (
          <a href={"#"} onClick={onClickDev} className="text-primary text-decoration-none">
            Simula la ricezione di un messaggio
          </a>
        )}
        <a
          href={profileURL}
          className={`auto-tooltip`}
          data-toggle={"tooltip"}
          title={tooltipProfile}
        >
          <img
            src={avatar ? avatar : defaultAvatar}
            className={`${style.profileButton}`}
          />
        </a>
        <a
          href={logoutURL}
          method={"delete"}
          rel={"nofollow"}
          data-method="delete"
          className={`auto-tooltip`}
          data-toggle={"tooltip"}
          title={tooltipLogout}
        >
          <LogoutIcon />
        </a>
      </div>
      {triggerButtons.length > 0 ? (
        <ModalUpdates
          triggerButtons={triggerButtons}
          snapToElement={"#cliccaQui"}
        />
      ) : null}
    </nav>
  );
}

MainNavbar.propTypes = {};

MainNavbar.defaultProps = {};

export default MainNavbar;
