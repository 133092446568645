import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./SinglePatient.module.sass";
import PatientBox from "../PatientBox/PatientBox";
import SavedSequence from "../FavouritePlans/SavedSequence/SavedSequence";
import LinkLoginCode from "../LinkLoginCode/LinkLoginCode";
import List from "../List/List";
import Slider from "../DailySequence/Slider/Slider";
import Call from "../Call/Call";

function SinglePatient(props) {
  const { patient } = props;

  const baseUrl = `/patients/${patient.id}.json`;

  const renderItem = (item) => {
    if (
      item.type == "daily_sequence" ||
      item.type == "kari_connect_daily_sequence" ||
      item.type == "xclinic_sequence"
    ) {
      return (
        <div className={style.itemContainer}>
          <Slider {...item} />
        </div>
      );
    }
    if (item.type == "call") {
      return (
        <div className={style.itemContainer}>
          <Call {...item} />
        </div>
      );
    }
    return <div></div>;
  };

  const showLoginCode = () => {
    return (patient.patientLoginCode &&
      <LinkLoginCode linkLoginCode={patient.patientLoginCode} linkLoginCodeText={patient.linkLoginCodeText} />
    );
  };

  return (
    <div className={`${style.SinglePatient}`}>
      <PatientBox {...patient} />
      {
        patient.hasActivities ? (
          <List baseQuery={baseUrl} renderItem={renderItem} />
        ) : (
          showLoginCode()
        )
      }
    </div>
  );
}

SinglePatient.propTypes = {};

SinglePatient.defaultProps = {};

export default SinglePatient;
