import React, { useEffect, useRef, useState } from "react";
import style from "./CallItem.module.sass";
import Actions from "../../../FavouritePlans/SavedSequence/Actions/Actions";

function CallItem(props) {
  const {
    deleteTextButton,
    editUrl,
    tooltipActions,
    patientIdentifier,
    day,
    time,
    url,
    callUrl,
  } = props;
  const [visible, setVisible] = useState(true);

  const onRemove = () => {
    setVisible(false);
    if (props.onRemove) {
      setTimeout(() => {
        props.onRemove();
      }, 600);
    }
  };

  let rootClass = `${style.CallItem} ${visible ? "" : style.CallItemHide}`;

  const clickOnEditURL = () => {
    const { id, patient_id, dateTime } = props;
    props.updateCall({ id, patient_id, dateTime }, editUrl, url);
  };

  const clickOnCamera = () => {
    window.open(callUrl, "videocall", "width=1000,height=700");
    window.onmessage = function (e) {
      if (e.data) {
        // FIXME: Commented because it executes right after the call window is opened.
        // window.location.replace(e.data.toString());
      }
    };
  };

  let commandsSequenceClass = style.commandsSequence;
  if (!callUrl) {
    commandsSequenceClass += " " + style.disableCall;
  }

  return (
    <div className={`${rootClass}`}>
      <div className={`${style.patientName}`}>{patientIdentifier}</div>
      <div className={`${style.date}`}>
        <div className={`${style.time}`}>{time}</div>
        <div className={`${style.day}`}>{day}</div>
      </div>
      <div className={`${commandsSequenceClass}`}>
        <Actions
          deleteTextButton={deleteTextButton}
          clickOnEdit={clickOnEditURL}
          tooltipActions={tooltipActions}
          removeFromList={onRemove}
          addEye={true}
          eyeUrl={url}
          addDuplicate={false}
          deleteUrl={url}
          onDeleteEnd={onRemove}
          addCall={true}
          clickOnCamera={clickOnCamera}
          addEdit={editUrl !== undefined}
        />
      </div>
    </div>
  );
}

CallItem.propTypes = {};

CallItem.defaultProps = {};

export default CallItem;
