import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./ExercisesList.module.sass";
import Exercise from "./Exercise/Exercise";

function ExercisesList(props) {
  const { exercisesGroups, district, districtIndex } = props;

  const renderExercises = (
    exercises,
    groupId,
    groupIndex,
    firstGroup,
    lastGroup
  ) => {
    return exercises.map((exercise, index) => {
      const firstExercise = firstGroup && index == 0;
      const lastExercise = lastGroup && index == exercises.length - 1;
      return (
        <li
          key={`exercise_${district}_${groupId}_${exercise.id}`}
          className={`mb-3`}
        >
          <Exercise
            {...exercise}
            district={district}
            groupId={groupId}
            districtIndex={districtIndex}
            groupIndex={groupIndex}
            index={index}
            firstExercise={firstExercise}
            lastExercise={lastExercise}
          />
        </li>
      );
    });
  };

  return (
    <div className={`${style.ExercisesList}`}>
      {exercisesGroups.map((exercisesGroup, index) => {
        const firstGroup = index == 0;
        const lastGroup = index == exercisesGroups.length - 1;
        return (
          <div key={`group_${district}_${exercisesGroup.id}_${index}`}>
            <div className={`${style.ExercisesListGroupTitle} text-white mb-3`}>
              {exercisesGroup.name}
            </div>
            <ul>
              {renderExercises(
                exercisesGroup.items,
                exercisesGroup.id,
                index,
                firstGroup,
                lastGroup
              )}
            </ul>
          </div>
        );
      })}
    </div>
  );
}

ExercisesList.propTypes = {};

ExercisesList.defaultProps = {};

export default ExercisesList;
