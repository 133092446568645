import React from "react";
import style from "./NameAndArrows.module.sass";
import ChevronUp from "./assets/chevron-up.svg";
import SequenceTypology from "../../../../../Sequence/SequenceTypology";
import VideoTooltip from "../../../../../VideoTooltip/VideoTooltip";

function NameAndArrows(props) {
  const { name, typology, enableArrowUp, enableArrowDown, videoUrl } = props;

  const limit = 34;
  const _name = name.length < limit ? name : name.substring(0, limit) + "...";

  const onClickMoveUp = () => {
    props.onChangePosition("up");
  };

  const onClickMoveDown = () => {
    props.onChangePosition("down");
  };

  return (
    <div className={`row ${style.NameAndArrows}`}>
      <div className={`col-2 text-center`}>
        <div className={`py-3 ms-3`}>
          <div
            onClick={onClickMoveUp}
            className={`${enableArrowUp ? "" : style.disable}`}
          >
            <img src={ChevronUp} />
          </div>
          <div
            className={`${style.moveDown} ${
              enableArrowDown ? "" : style.disable
            }`}
            onClick={onClickMoveDown}
          >
            <img src={ChevronUp} />
          </div>
        </div>
      </div>
      <div className={`col-10`}>
        <div className={`py-3 ms-2`}>
          <VideoTooltip videoUrl={videoUrl}>
            {_name}
            <div>
              <SequenceTypology name={typology?.name} code={typology?.code} />
            </div>
          </VideoTooltip>
        </div>
      </div>
    </div>
  );
}

NameAndArrows.propTypes = {};

NameAndArrows.defaultProps = {
  enableArrowUp: true,
  enableArrowDown: true,
};

export default NameAndArrows;
