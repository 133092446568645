import React, { useEffect, useRef } from "react";
import style from "./ExerciseItem.module.sass";
import SequenceTypology from "../../Sequence/SequenceTypology";
import ToolLabel from "./ToolLabel/ToolLabel";
import Button from "../../Button/Button";
function ExerciseItem(props) {
  const { id, clickOnAdd, number, typology, tool, title, videoSrc, videoThumbSrc, buttons, durationMedicairLabel } =
    props;
  return (
    <div
      className={`${style.ExerciseItem}`}
      data-id={id}
      data-video-src={videoSrc}
    >
      <div className={`${style.mainContainer}`}>
        <img src={videoThumbSrc} className={`${style.media}`} />
        <div className={`${style.nameContainer}`}>
          <div className={`${style.id}`}>{typology.code == 'TYPE_MEDICAIR' ? `${durationMedicairLabel}` : `ID.${number}`}</div>
          <div className={`${style.name}`}>
            <div>{title}</div>
            {/* FIXME: Improve this elegant crap */}
            {(typology.code == 'TYPE_MEDICAIR') && <ToolLabel code={tool.code} title={tool.title} /> || <SequenceTypology code={typology.code} name={typology.title} />}
          </div>
        </div>

        <div className={`${style.buttonTo}`}>
          <Button
            kariBtn
            kariBtnGreenOver
            kariBtnArrowRight
            onClick={() => clickOnAdd(id)}
          >
            {buttons.add}
          </Button>
        </div>
      </div>
    </div>
  );
}

ExerciseItem.propTypes = {};

ExerciseItem.defaultProps = {
  code: "TYPE_BAL",
};

export default ExerciseItem;
