import React, { useState } from "react";
import style from "./Patients.module.sass";
import PatientListHeader from "./PatientListHeader/PatientListHeader";
import PatientBox from "../PatientBox/PatientBox";
import List from "../List/List";
import FirstAccess from "../FirstAccess/FirstAccess";


function Patients(props) {
  const { firstAccess, searchSelectOptions, searchPatientsPlaceholderText, isMedicair, showArchivedPatientsTooltipText, hideArchivedPatientsTooltipText } = props;
  const baseUrl = "/patients";
  const [queryUrl, setQueryUrl] = useState(
    baseUrl + "?name=&order_by=last_sequence_date&direction=desc&active=true"
  );

  const onChangeFilter = (newOrder, search = "", direction = "", active = true) => {
    let newQueryUrl =
      baseUrl +
      "?name=" +
      search +
      "&order_by=" +
      newOrder +
      "&direction=" +
      direction +
      "&active=" +
      active;
    setQueryUrl(newQueryUrl);
  };

  const renderPatientItem = (item) => {
    return <PatientBox {...item} />;
  };

  return (
    <div className={`${style.PatientsList}`}>
      <div className={"row mb-4"}>
        <PatientListHeader
          onChangeFilter={onChangeFilter}
          searchSelectOptions={searchSelectOptions}
          searchPatientsPlaceholderText={searchPatientsPlaceholderText}
          showArchivedPatientsTooltipText={showArchivedPatientsTooltipText}
          hideArchivedPatientsTooltipText={hideArchivedPatientsTooltipText}
          isMedicair={isMedicair}
        />
      </div>
      {firstAccess.check ? (
        <FirstAccess newPlanUrl={firstAccess.newPlanUrl} newPatientUrl={firstAccess.newPatientUrl} newPlanHidden={firstAccess.newPlanHidden} />
      ) : (
        <List baseQuery={queryUrl} renderItem={renderPatientItem} />
      )}
    </div>
  );
}

export default Patients;
