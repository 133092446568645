import React, { useEffect, useRef } from "react";
import style from "./ExerciseSequenceChart.module.sass";

import { generatePulseOximeterData, configPulseOximeter, initialsOf } from "./utilsMedicair";
function ExerciseSequenceChartPulseOximeter(props) {
  let { title, heartRateData, saturationData, heartRateLabel, saturationLabel, medicairData } = props;
  const chart = useRef(null);
  const canvasContainerRef = useRef();
  const data = generatePulseOximeterData(heartRateLabel, saturationLabel, heartRateData, saturationData);

  useEffect(() => {
    let canvas;
    if (canvasContainerRef.current) {
      canvas = canvasContainerRef.current.querySelector("canvas");
      if (canvas) {
        canvas.remove();
      }
      canvas = document.createElement("canvas");
      // canvas.dataset.side = side;
      canvasContainerRef.current.appendChild(canvas);
      chart.current = new Chart(canvas, configPulseOximeter(data));
    }
  }, [props]);

  return (
    <div className={`${style.exerciseSequenceChartMedicair}`}>
      <div className={`${style.exerciseSequenceChartTitle}`}>{title}</div>
      <div className={`${style.Subtitles}`}>
        <div className={`${style.Subtitle}`}>
          <span className={`${style.HeartRateLabel}`}>{initialsOf(heartRateLabel)}</span>
          <span className={`${style.Label}`}>max</span>
          <span className={`${style.Value}`}>{medicairData.maxHeartRate}</span>
          <span className={`${style.Label}`}>min</span>
          <span className={`${style.Value}`}>{medicairData.minHeartRate}</span>
          <span className={`${style.Label}`}>avg</span>
          <span className={`${style.Value}`}>{medicairData.avgHeartRate}</span>
        </div>
        <div className={`${style.Subtitle}`}>
          <span className={`${style.OxygenSaturationLabel}`}>SpO<sub>2</sub></span>
          <span className={`${style.Label}`}>max</span>
          <span className={`${style.Value}`}>{medicairData.maxOxygenSaturation}</span>
          <span className={`${style.Label}`}>min</span>
          <span className={`${style.Value}`}>{medicairData.minOxygenSaturation}</span>
          <span className={`${style.Label}`}>avg</span>
          <span className={`${style.Value}`}>{medicairData.avgOxygenSaturation}</span>
        </div>
      </div>
      <div className={`${style.chartContainer}`} ref={canvasContainerRef}></div>
    </div>
  );
}

export default ExerciseSequenceChartPulseOximeter;
