import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./LeftMenu.module.sass";

import Main from "../../icons/Main";
import ManageTemplate from "../../icons/ManageTemplate";
import Videocamera from "../../icons/Videocamera";
import Chats from "../../icons/Chats";
import Bell from "../../icons/Bell";
import Triage from "../../icons/Triage";
import Help from "../../icons/Help";
import Gear from "../../icons/Gear";
import MenuItem from "./MenuItem/MenuItem";

function LeftMenu(props) {
  const { items, logo } = props;
  const iconComponents = {
    Main,
    ManageTemplate,
    Videocamera,
    Chats,
    Bell,
    Triage,
    Help,
    Gear,
  };

  const getIcon = (name) => {
    var MyComponent = iconComponents[name];
    return <MyComponent />;
  };

  return (
    <div className={`${style.LeftMenu}`}>
      <a href={logo.url}>
        <div className={`${style[logo.className]}`} />
      </a>
      <ul>
        {items.map((item) => {
          return item.hide ? null : (
            <MenuItem key={item.icon} {...item}>
              {getIcon(item.icon)}
            </MenuItem>
          );
        })}
      </ul>
    </div>
  );
}

LeftMenu.propTypes = {};

LeftMenu.defaultProps = {};

export default LeftMenu;
