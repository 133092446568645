import React, { useEffect, useRef, useContext, useState } from "react";
import PropTypes from "prop-types";
import style from "./VideoGrid.module.sass";
import CallButtons from "../CallButtons/CallButtons";
import { LiveCallsContext } from "../LiveCalls";
import FeedbackButton from "../FeedbackButton/FeedbackButton";

function VideoGrid(props) {
  const { isRecording } = props;
  const value = useContext(LiveCallsContext);
  const { translations } = value;
  const [showVideo, setShowVideo] = useState(false);

  const onClickReload = () => {
    location.reload();
  };

  useEffect(() => {
    setTimeout(function () {
      if (rtc.remoteStreams.length == 0) {
        setShowVideo(true);
      }
    }, 30 * 1000);
  }, []);

  return (
    <div className={`${style.VideoGrid}`} id={"video"}>
      <div className={style.VideoView}>
        <div
          className={`${style.VideoPlaceHolder} video-placeholder`}
          id={"local_stream"}
        ></div>
        <div className={`hide video-profile`} id={"local_video"}></div>
        <div
          className={"autoplay-fallback hide"}
          id={"video_autoplay_local"}
        ></div>
      </div>
      <div className={style.BackgroundOngoingCall}>
        {!showVideo && (
          <div className={`${style.TextOngoing} text-ongoing no-call-hide`}>
            {translations.onGoing}
          </div>
        )}
        <div className={`${style.TextEnded} d-none`}>{translations.ended}</div>
        {showVideo && (
          <div className={`${style.TextOngoing} text-ongoing no-call-show`}>
            {translations.noAnswer}
            <div className={"row justify-content-center"}>
              <small className={"col-lg-8"}>
                <div
                  className={`${style.RecallButton}`}
                  onClick={onClickReload}
                >
                  {translations.recall}
                </div>
              </small>
            </div>
          </div>
        )}
      </div>
      <div className={`${style.CallButtons}`}>
        <CallButtons />
        {isRecording && (
          <div className={style.FeedbackContainer}>
            <FeedbackButton />
          </div>
        )}
      </div>
    </div>
  );
}

VideoGrid.propTypes = {};

VideoGrid.defaultProps = {};

export default VideoGrid;
