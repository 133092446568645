import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./NotificationSetting.module.sass";
import PatientHomeNotification from "./PatientHomeNotification/PatientHomeNotification";
import ReportNotifications from "./ReportNotifications/ReportNotifications";
import LanguageChanger from "./LanguageChanger/LanguageChanger";
import { SettingsContext } from "../Settings";

function NotificationSetting(props) {
  const { } = props;
  const value = useContext(SettingsContext);


  const reportVisible = () => {
    return value.sections.report.visible;
  }

  return (
    <div className={`${style.NotificationSetting}`}>
      <PatientHomeNotification />
      {reportVisible() &&
        <div>
          <hr className={`white`} />
          <ReportNotifications />
        </div>
      }
      <hr className={`white`} />
      <LanguageChanger />
    </div>
  );
}

NotificationSetting.propTypes = {
};

NotificationSetting.defaultProps = {
};

export default NotificationSetting