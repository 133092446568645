import React, { useContext, useEffect, useRef } from "react";
import style from "./BulkChangeActions.module.sass";
import LegendLabel from "../../Sequence/LegendLabel";
import RadiosBlock from "./RadiosBlock/RadiosBlock";
import { CustomizationsPageContext } from "../CustomizationsPage";
import { callApi, camelize } from "../../../helpers";

function BulkChangeActions(props) {
  const {} = props;
  const { state, dispatcher } = useContext(CustomizationsPageContext);
  const { bulkActions } = state;

  const update_global_property = (property, value) => {
    callApi(
      bulkActions.urls.update + "?" + property + "=" + value,
      "POST",
      "json",
      (data) => {
        const { duration, durationText, durationWarning, bulk } = data;
        console.log('data', data)
        dispatcher({
          type: "update_global_property",
          value,
          property: camelize(property),
          updatedExercises: bulk['exercises'],
          bulkActionsProperty: property.split("_")[0],
          duration,
          durationText,
          durationWarning
        });
      }
    );
  };

  const onChangeSide = (value) => {
    update_global_property("side_id", value);
  };

  const onChangeDifficulty = (value) => {
    update_global_property("difficulty_id", value);
  };

  return (
    <div
      className={`${style.BulkChangeActions} row mb-3 text-center align-padding-right`}
    >
      <div className={`col-sm-4`}></div>
      <div className={`col-sm-1`}>
        <LegendLabel label={bulkActions?.translations?.set} />
      </div>
      <div className={`col-sm-2`}>
        <RadiosBlock
          {...bulkActions.side}
          onChangeRadio={onChangeSide}
          title={bulkActions?.translations?.side}
          message={bulkActions?.translations?.sideNotice}
          selected={bulkActions.side.selected}
        />
      </div>
      <div className={`col-sm-2 offset-sm-1`}>
        <RadiosBlock
          {...bulkActions.difficulty}
          onChangeRadio={onChangeDifficulty}
          title={bulkActions?.translations?.difficulty}
          selected={bulkActions.difficulty.selected}
        />
      </div>
      <div className={`col-sm-1`}>
        <LegendLabel label={bulkActions?.translations?.repetitionLabel} />
      </div>
    </div>
  );
}

BulkChangeActions.propTypes = {};

BulkChangeActions.defaultProps = {};

export default BulkChangeActions;
