import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./PersonalSequencePageHeader.module.sass";

function PersonalSequencePageHeader(props) {
  const { backBtn, title } = props;

  return (
    <div className={`${style.PersonalSequencePageHeader}`}>
      <div className={style["right-upper"]}>{title}</div>
    </div>
  );
}

PersonalSequencePageHeader.propTypes = {};

PersonalSequencePageHeader.defaultProps = {};

export default PersonalSequencePageHeader;
