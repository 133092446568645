import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { SettingsContext } from "../../Settings";

function PatientHomeNotification(props) {
  const {} = props;
  const value = useContext(SettingsContext);
  const saveSetting = value.saveSetting;
  const translations = value.translations;
  const notifications = value.sections.notifications;
  const [finish, setFinish] = useState(notifications.finish.checked);
  const [pain, setPain] = useState(notifications.pain.checked);
  const infoLabel1Ref = useRef();
  const infoLabel2Ref = useRef();

  const clickOnCheckbox = (e, setting) => {
    const value = setting == "finish" ? !finish : !pain;
    updateValue(setting, value);
  };

  const updateValue = (type, value) => {
    saveSetting(notifications.url, {
      body: JSON.stringify({ data: { type, value: value ? "true" : "false" } }),
    });
    if (type == "pain") {
      setPain(value);
    }
    if (type == "finish") {
      setFinish(value);
    }
  };

  useEffect(() => {
    $(infoLabel1Ref.current).tooltip();
    $(infoLabel2Ref.current).tooltip();
  });

  return (
    <div>
      <h2>{translations.settings.notifications.label}</h2>
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: translations.settings.notifications.kind_html,
          }}
        />
        <br />
        <div>
          <input
            id="finish"
            type={"checkbox"}
            checked={finish}
            onChange={(e) => clickOnCheckbox(e, "finish")}
          />
          <label htmlFor={"finish"}>
            {translations.settings.notifications.session.finish}
            <span
              data-info=""
              data-placement={"right"}
              title={translations.tooltip.notifications.session.finish}
              ref={infoLabel1Ref}
            >
              i
            </span>
          </label>
        </div>
        <div>
          <input
            id="pain"
            type={"checkbox"}
            checked={pain}
            onChange={(e) => clickOnCheckbox(e, "pain")}
          />
          <label htmlFor={"pain"}>
            {translations.settings.notifications.session.pain}
            <span
              data-info=""
              data-placement={"right"}
              title={translations.tooltip.notifications.session.pain}
              ref={infoLabel2Ref}
            >
              i
            </span>
          </label>
        </div>
      </div>
    </div>
  );
}

PatientHomeNotification.propTypes = {};

PatientHomeNotification.defaultProps = { finish: true, pain: true };

export default PatientHomeNotification;
