import React, { useEffect, useRef } from "react";
import style from "./TimingBlock.module.sass";

function TimingBlock(props) {
  const { message, duration, durationText } = props;
  const timingRef = useRef();
  const durationLimit = 35 * 60;

  useEffect(() => {
    setTooltip();
  }, []);

  useEffect(() => {
    setTooltip();
  }, [duration]);

  const setTooltip = () => {
    if (duration >= durationLimit) {
      addDurationTooltip();
    } else {
      removeDurationTooltip();
    }
  };

  const addDurationTooltip = () => {
    $(timingRef.current).addClass("auto-tooltip");
    $(timingRef.current).attr("data-placement", "top");
    const message = $(timingRef.current).data("message");
    $(timingRef.current).attr("title", message);
    $(timingRef.current).tooltip();
  };

  const removeDurationTooltip = () => {
    $(timingRef.current).attr("data-placement", "");
    $(timingRef.current).attr("title", "");
    $(timingRef.current).tooltip("dispose");
  };

  let rootClass = `${style.TimingBlock}`;
  if (duration >= durationLimit) {
    rootClass += ` ${style.TimingBlockDanger}`;
  }

  return (
    <div className={rootClass}>
      <div ref={timingRef} data-message={message}>
        {durationText}
      </div>
    </div>
  );
}

TimingBlock.propTypes = {};

TimingBlock.defaultProps = {};

export default TimingBlock;
