import React, { useEffect, useReducer, useRef, useState } from "react";
import style from "./EditPlan.module.sass";
import List from "../List/List";
import ExerciseItem from "./ExerciseItem/ExerciseItem";
import { callApi } from "../../helpers";
import EditPlanSummaryBlock from "./EditPlanSummaryBlock/EditPlanSummaryBlock";
import EditPlanHeader from "./EditPlanHeader/EditPlanHeader";
import { reducer, initState } from "./EditPlanState";
function EditPlan(props) {
  const { id, districtId, planSummaryUrl, type, showFilters } = props;
  const [translations, setTranslations] = useState(null);
  const [state, dispatcher] = useReducer(reducer, { districtId }, initState);
  const baseUrl = "/sequences_area/exercises.json";
  const [queryUrl, setQueryUrl] = useState(
    baseUrl + "?district_id=" + districtId
  );
  const [isLoading, setIsLoading] = useState(false);
  const editPlanListRef = useRef();

  useEffect(() => {
    getPlanSummary();
    window.addEventListener("resize", onResize.bind(this));
    onResize();
  }, []);

  useEffect(() => {
    onChangeFilter();
  }, [state.district]);

  const onResize = () => {
    const blockBoundingBox = editPlanListRef.current.getBoundingClientRect();
    const newHeight = window.innerHeight - blockBoundingBox.y;
    editPlanListRef.current.style.height = `${newHeight}px`;
  };

  const clickOnAddItem = (exerciseId) => {
    callApi(
      state.addURL,
      "POST",
      "json",
      getPlanSummary,
      getPlanSummary,
      getPlanSummary,
      {
        body: JSON.stringify({
          sequence_id: id,
          district_id: state.district,
          exercise_id: exerciseId,
        }),
      }
    );
  };

  const removeItem = (url) => {
    callApi(url, "DELETE", "", getPlanSummary);
  };

  const getPlanSummary = () => {
    // `/personal_sequence_area/personal_sequences/${id}/personal_sequences_exercises.json}`,
    callApi(planSummaryUrl, "GET", "json", onGetSummary);
  };

  const onGetSummary = (data) => {
    dispatcher({
      type: "update_plan_summary",
      ...data,
    });
  };

  const renderItem = (item) => {
    return <ExerciseItem {...item} clickOnAdd={clickOnAddItem} />;
  };

  const onChangeFilter = (parameters = "") => {
    let newQueryUrl = baseUrl;
    if (parameters) {
      newQueryUrl += "?" + parameters;
      newQueryUrl += "&district_id=" + state.district;
    } else {
      newQueryUrl += "?district_id=" + state.district;
    }
    setIsLoading(true);
    setQueryUrl(newQueryUrl);
  };

  const onItemsLoaded = (items, data) => {
    let newAddUrl = data.addPersonalSequencesExerciseUrl;
    if (type == "saved_sequences") {
      newAddUrl = data.addSavedSequencesExerciseUrl;
    }
    dispatcher({
      type: "update_add_url",
      addURL: newAddUrl,
    });
    dispatcher({
      type: "update_tot_found",
      totFound: data.pagy.count,
    });
    if (!translations) {
      setTranslations(data.translations);
    }
    setIsLoading(false);
    new VideoHandlerObserver(editPlanListRef.current);
    TweenLite.to(editPlanListRef.current, 0.3, { scrollTop: 0 });
  };

  return (
    <div className={`${style.EditPlan}`}>
      <EditPlanContext.Provider
        value={{ state, dispatcher, removeItem, translations, isLoading }}
      >
        <div className={`${style.EditPlanHeader}`}></div>
        <div className={`${style.EditPlanContent}`}>
          <div className={`${style.EditPlanContentLeft}`}>
            <EditPlanHeader
              onChangeFilter={onChangeFilter}
              id={id}
              district={state.district}
              translations={translations}
              showFilters={showFilters}
            />
            <div className={`${style.EditPlanContentLeftIn}`}>
              <div
                className={`${style.EditPlanContentLeftInScroll}`}
                ref={editPlanListRef}
              >
                <List
                  baseQuery={queryUrl}
                  renderItem={renderItem}
                  showPagination={true}
                  onItemsLoaded={onItemsLoaded}
                />
              </div>
            </div>
          </div>
          <div className={`${style.EditPlanContentRight}`}>
            <EditPlanSummaryBlock nextStep={state.nextStep} />
          </div>
        </div>
      </EditPlanContext.Provider>
    </div>
  );
}

EditPlan.propTypes = {};

EditPlan.defaultProps = { showFilters: true };

export const EditPlanContext = React.createContext({});

export default EditPlan;
