import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import styles from "./ModalContent.module.sass";

const ModalContent = (props) => {
  const { opacity, snapToElement } = props;
  const contentStyle = { opacity: opacity };

  if (snapToElement) {
    const el = document.querySelector(snapToElement);
    const elBoundingRect = el.getBoundingClientRect();
    const divider = window.innerWidth < 1300 ? 2 : 1;
    const marginFromElement = 10
    contentStyle["top"] = elBoundingRect.y + elBoundingRect.height + marginFromElement;
    contentStyle["left"] = elBoundingRect.x + elBoundingRect.width / divider;
    contentStyle["transform"] = `translate(${-100 / divider}%, 0%)`;
  }

  return (
    <div className={styles.modalContent}>
      <div className={styles.content} style={contentStyle}>
        {props.children}
      </div>
    </div>
  );
};

ModalContent.propTypes = {
  opacity: PropTypes.number,
};

ModalContent.defaultProps = {
  /** Set opacity */
  opacity: 1,
};

export { ModalContent as default };
