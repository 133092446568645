import React, { Fragment, useRef, useState } from "react";
import { callApi } from "../../helpers";
import DetailsCall from "../DetailsCall/DetailsCall";
import classes from "./Call.module.sass";

function Call(props) {
  const { date, weekDay, startedAt, duration, durationLabel, icon, url } =
    props;

  const [showDetailCall, setShowDetailCall] = useState(false);

  const getMainContent = () => {
    callApi(url, "GET", "json", (data) => {
      setContent(data);
    });
  };

  const clickOnTop = () => {
    if (showDetailCall) {
      setShowDetailCall(false);
    } else {
      setShowDetailCall(true);
    }
  };

  return (
    <div
      className={`${classes.bgGradient} ${classes.bgGradientClose} ${classes.call}`}
    >
      <div className="row" onClick={clickOnTop}>
        <div className="col col-lg-10 d-flex align-items-center my-3">
          <div
            className={`d-inline ps-4 ${classes.textSecondary} me-2`}
            dangerouslySetInnerHTML={{ __html: icon }}
          />
          <div className={`d-inline ${classes.label}`}>{durationLabel}</div>
          <div className={`d-inline ${classes.duration} ps-2`}>{duration}</div>
        </div>
        <div
          className="col align-self-center my-3"
          style={{ lineHeight: 12 + "px" }}
        >
          <div className={classes.textSecondary}>{date}</div>
          <div>
            <span className={classes.textSecondary}>{weekDay}</span>
          </div>
          {startedAt != "(--)" ? (
            <div>
              <span className={classes.textSecondary}>{startedAt}</span>
            </div>
          ) : null}
        </div>
      </div>
      <div className="main-content">
        {showDetailCall ? <DetailsCall callURL={url} insideBox={true} /> : null}
      </div>
    </div>
  );
}

export default Call;
