import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./Sequences.module.sass";
import Sequence from "./Sequence/Sequence";
import { PersonalSequencePageContext } from "../PersonalSequencePage";

function Sequences(props) {
  const { state, setState } = useContext(PersonalSequencePageContext);
  const { personalSequences, translations } = state;
  const onChangeSequenceName = (newName, index) => {
    const newState = JSON.parse(JSON.stringify(state));
    newState.personalSequences[index].name = newName;
    setState(newState);
  };

  return (
    <div className={`${style.Sequences}`}>
      <ul>
        {personalSequences.map((item, index) => {
          return (
            <Sequence
              {...item}
              key={`session_${item.id}`}
              translations={translations ? translations : null}
              index={index}
              onChangeName={onChangeSequenceName}
            />
          );
        })}
      </ul>
    </div>
  );
}

Sequences.propTypes = {};

Sequences.defaultProps = {};

export default Sequences;
