import React, { useEffect, useRef } from "react";
import classes from "./ExerciseName.module.sass";

function ExerciseName(props) {
  const { name, code, title, textColor } = props;
  const nameLenghtLimit = 22;
  const tooltipRef = useRef(null);

  const finalName =
    name.length > nameLenghtLimit
      ? name.slice(0, nameLenghtLimit) + "..."
      : name;

  useEffect(() => {
    if (tooltipRef.current) {
      $(tooltipRef.current).tooltip();
    }
  }, []);

  return (
    <div className={classes.exerciseNameRoot}>
      <div className={classes.exerciseCode}>{code}</div>
      <div
        className={classes.exerciseName}
        data-html="true"
        ref={tooltipRef}
        data-placement="top"
        title={name}
        style={{color: textColor}}
      >
        {finalName}
      </div>
      <div className={classes.exerciseSide}>{title}</div>
    </div>
  );
}

export default ExerciseName;
