import React, { useEffect, useRef, useState } from "react";


function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}

function debounce(callback, wait, immediate = false) {
  let timeout = null;
  return function () {
    const callNow = immediate && !timeout;
    const next = () => callback.apply(this, arguments);
    clearTimeout(timeout);
    timeout = setTimeout(next, wait);
    if (callNow) {
      next();
    }
  };
}

async function callApiASync(
  url,
  method = "POST",
  respType = "",
  onSuccess = null,
  onError = null,
  onFinally = null,
  params = {},
  headers = null
) {
  const load = {
    method,
    ...params,
  };
  if (method == "POST") {
    load["headers"] = {
      "Content-Type": "application/json",
    };
  }
  if (headers) {
    load["headers"] = { ...load["headers"], ...headers };
  }
  const response = await fetch(url, load);
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    throw new Error(message);
  }
  let result;
  if (respType == "") result = response;
  if (respType == "json") result = await response.json();
  if (respType == "text") result = await response.text();
  return result;
}

function callApi(
  url,
  method = "POST",
  respType = "",
  onSuccess = null,
  onError = null,
  onFinally = null,
  params = {},
  headers = null
) {
  const load = {
    method,
    ...params,
  };
  if (method == "POST" || method == "PATCH") {
    load["headers"] = {
      "Content-Type": "application/json",
    };
  }
  if (headers) {
    load["headers"] = { ...load["headers"], ...headers };
  }
  fetch(url, load)
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        response.json().then((data) => {
          const { errors } = data;
          let errorsString = "";
          if (errors.length > 0) {
            errors.reduce((prev, current) => prev + current);
          }
          var error = new Error(errorsString);
          throw error;
        });
      } else {
        if (respType == "") return response;
        if (respType == "json") return response.json();
        if (respType == "text") return response.text();
      }
    })
    .then((data) => {
      if (onSuccess) {
        onSuccess(data);
      }
    })
    .catch((e) => {
      console.warn("errore -->", e);
      if (onError) {
        onError(e);
      }
    })
    .finally(() => {
      if (onFinally) {
        onFinally();
      }
    });
}

function getBaseURL(url) {
  const indexOfDivider = url.indexOf("?");
  return indexOfDivider > 0 ? url.substring(0, indexOfDivider) : url;
}

function getTransitionEndEventName() {
  var transitions = {
    transition: "transitionend",
    OTransition: "oTransitionEnd",
    MozTransition: "transitionend",
    WebkitTransition: "webkitTransitionEnd",
  };
  let bodyStyle = document.body.style;
  for (let transition in transitions) {
    if (bodyStyle[transition] != undefined) {
      return transitions[transition];
    }
  }
}

/**
 * Hook that alerts clicks outside of the passed ref from here: https://stackoverflow.com/a/42234988
 */

function useOutsideElement(ref, fcn, excludeRefs = []) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      let clickOnExcludedRef = 0;
      excludeRefs.map((excludeItem) => {
        clickOnExcludedRef += excludeItem.current == event.target ? 1 : 0;
      });
      if (clickOnExcludedRef == 0) {
        if (ref.current && !ref.current.contains(event.target)) {
          fcn();
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

function triggerTextChange(e, fcn, prevValue, maxLength = 2) {
  const word = e.target.value;
  if (maxLength == 0) {
    fcn(word);
  } else {
    const newWordLength = word.length;
    const oldWordLength = prevValue.length;
    if (newWordLength > maxLength || newWordLength < oldWordLength) {
      fcn(word);
    }
  }
}



function getYearMonthDayFromTimePickerDate(dateStr, locale) {
  const [dateRelated, timeRelated] = dateStr.split(" ");
  let day, month, year;
  const dateArray = dateRelated.split("-");
  if (locale == "it") {
    day = dateArray[0];
    month = dateArray[1];
    year = dateArray[2];
  } else {
    day = dateArray[1];
    month = dateArray[0];
    year = dateArray[2];
  }
  return {
    year,
    month,
    day,
  };
}

function camelize(s) {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
}

function convertToHumanDateFormat(milliseconds, locale) {
  const dateObject = milliseconds ? new Date(milliseconds) : new Date();
  let humanDateFormat = dateObject.toLocaleString(locale, {
    hourCycle: "h23",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  humanDateFormat = humanDateFormat.replace(",", "");
  return humanDateFormat;
}

export {
  useForceUpdate,
  debounce,
  callApi,
  callApiASync,
  getBaseURL,
  getTransitionEndEventName,
  useOutsideElement,
  triggerTextChange,
  camelize,
  convertToHumanDateFormat,
  getYearMonthDayFromTimePickerDate,
};
