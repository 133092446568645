/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "@fortawesome/fontawesome-free/css/all"

import 'application'

import ReactComponentsHandler from "./../react/reactComponentsHandler";
const reactComponentsHandler = new ReactComponentsHandler();

import ChangeOrderExercises from './../js-legacy/sequence/component/change_order_exercises'
import DailySequenceCollector from './../js-legacy/daily_sequence/daily_sequence_collector'

import 'react-tippy/dist/tippy.css';

document.addEventListener("turbolinks:load", () => {
  reactComponentsHandler.insertReactComponents();
});


// Do a disposizione agli script esterni ChangeOrderExercises
window.ChangeOrderExercises = ChangeOrderExercises
window.DailySequenceCollector = DailySequenceCollector