import React from "react";

const Main = () => {
  return (
    <svg
      height="35"
      viewBox="0 0 35 35"
      width="35"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m11.5 13.125c.2200532 0 .410481.0804028.5712891.2412109s.2412109.3512359.2412109.5712891v16.25c0 .2200532-.0804028.410481-.2412109.5712891s-.3512359.2412109-.5712891.2412109h-5.6875c-.22005318 0-.41048097-.0804028-.57128906-.2412109-.1608081-.1608081-.24121094-.3512359-.24121094-.5712891v-16.25c0-.2200532.08040284-.410481.24121094-.5712891.16080809-.1608081.35123588-.2412109.57128906-.2412109zm-.8125 16.25v-14.625h-4.0625v14.625zm9.75-11.4257812c.2200532 0 .410481.0804028.5712891.2412109s.2412109.3512359.2412109.5712891v11.4257812c0 .2200532-.0804028.410481-.2412109.5712891s-.3512359.2412109-.5712891.2412109h-5.6875c-.2369804 0-.4316399-.0804028-.5839844-.2412109s-.2285156-.3512359-.2285156-.5712891v-11.4257812c0-.2200532.0761711-.410481.2285156-.5712891s.347004-.2412109.5839844-.2412109zm-.8125 11.4257812v-9.8007812h-4.0625v9.8007812zm9.75-24.375c.2200532 0 .410481.08040284.5712891.24121094.1608081.16080809.2412109.35123588.2412109.57128906v24.375c0 .2200532-.0804028.410481-.2412109.5712891s-.3512359.2412109-.5712891.2412109h-5.6875c-.2200532 0-.410481-.0804028-.5712891-.2412109s-.2412109-.3512359-.2412109-.5712891v-24.375c0-.22005318.0804028-.41048097.2412109-.57128906.1608081-.1608081.3512359-.24121094.5712891-.24121094zm-.8125 24.375v-22.75h-4.0625v22.75z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default Main;
