import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./DistrictBlock.module.sass";
import ExercisesList from "../ExercisesList/ExercisesList";

function DistrictBlock(props) {
  const { district, index } = props;

  return (
    <div className={`${style.DistrictBlock}`}>
      <h1 className={`${style.DistrictBlock} text-white`}>{district.name}</h1>
      <ExercisesList
        exercisesGroups={district.groups}
        district={district.id}
        districtIndex={index}
      />
    </div>
  );
}

DistrictBlock.propTypes = {};

DistrictBlock.defaultProps = {};

export default DistrictBlock;
