import React, { useEffect, useState, version } from "react";
import style from "./FirstAccess.module.sass";
import Button from "../Button/Button";
import ManageTemplate from "../../icons/ManageTemplate";
import AddPatient from "../../icons/AddPatient";

function FirstAccess(props) {
  const { newPlanUrl, newPatientUrl, newPlanHidden } = props;

  return (
    <div className={`${style.FirstAccess}`}>
      <div className={style.box}>
        Pronto? VIA!
        <br />
        Inizia
        {" "}
        <span className={style.textHighlight}>creando un paziente</span>
        <br />
        {
          !newPlanHidden &&
          <div>
            o un
            {" "}
            <span className={style.textHighlight}>piano di allenamento</span>
          </div>
        }
      </div>
      <div className={style.buttons}>
        {
          !newPlanHidden &&
          <Button kariBtnGreen href={newPlanUrl}>
            <ManageTemplate />
            {" "}
            Crea piano
          </Button>
        }
        <Button kariBtnGreen href={newPatientUrl}>
          <AddPatient />
          {" "}
          Nuovo paziente
        </Button>
      </div>
    </div>
  );
}

FirstAccess.propTypes = {
};

FirstAccess.defaultProps = {
};

export default FirstAccess
