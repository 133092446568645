import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import classes from "./XclinicDuration.module.sass";

function XclinicDuration(props) {
  const { minutes, label } = props;

  return (
    <div className={classes.repetitions}>
      <div className={classes.label}>{label}</div>
      <div style={{ top: 10 + 'px' }} className="text-center pt-3">
        <div className={classes.done}>{`${minutes} min.`}</div>
      </div>
    </div>
  );
}

XclinicDuration.propTypes = {
  label: PropTypes.string,
  minutes: PropTypes.number,
};

XclinicDuration.defaultProps = {
  label: "",
  minutes: 0,
};

export default XclinicDuration;
