import React, { Fragment, useState } from "react";
import { debounce, triggerTextChange } from "../../../helpers";
import classes from "./PatientListHeader.module.sass"

function PatientListHeader(props) {
  const { onChangeFilter, searchSelectOptions, searchPatientsPlaceholderText, isMedicair, showArchivedPatientsTooltipText, hideArchivedPatientsTooltipText } = props;

  const [orderBy, setOrderBy] = useState(searchSelectOptions[1].value);
  const [search, setSearch] = useState("");
  const [direction, setDirection] = useState("desc");
  const [active, setActive] = useState(true);

  const userSelectNewOrder = (e) => {
    const newOrderBy = e.target.value;
    const newDirection = searchSelectOptions[e.target.selectedIndex].direction;
    setOrderBy(newOrderBy);
    setDirection(newDirection);
    onChangeFilter(newOrderBy, search, newDirection ? newDirection : "asc", active);
  };

  const handleSearchChange = debounce((e) => {
    triggerTextChange(
      e,
      (text) => {
        setSearch(text);
        onChangeFilter(orderBy, text, direction ? direction : "asc");
      },
      search
    );
  }, 200);

  const userIsSearching = (e) => {
    handleSearchChange(e);
  };

  const renderOptions = () => {
    return searchSelectOptions.map((option) => {
      return (
        <option key={option.value} value={option.value}>
          {option.text}
        </option>
      );
    });
  };

  const toggleActive = () => {
    onChangeFilter(orderBy, search, direction, !active);
    setActive(!active);
  };

  return (
    <Fragment>
      <div className={`col position-relative ${active ? '' : `${classes.disabled}`}`}>
        <i
          className={`fa fa-search ${classes.iconSearch}`}
        ></i>
        <input
          id="patient-search"
          className={`form-control input-kari ps-5`}
          onChange={userIsSearching}
          type={"text"}
          placeholder={searchPatientsPlaceholderText}
        />
      </div>
      <div className={`col position-relative ${active ? '' : `${classes.disabled}`}`}>
        <div className={`d-flex text-end`}>
          <span className={`fa-rotate-270 d-inline-block ${classes.iconSort}`}>
            <i className={`fa fa-signal fa-flip-vertical`}></i>
          </span>
          <select
            id="patient-order-by"
            className={"select kari-select ps-5"}
            onChange={userSelectNewOrder}
            value={orderBy}
          >
            {renderOptions()}
          </select>
          <i
            className={`fa fa-angle-down ${classes.iconOrder}`}
          ></i>
        </div>
      </div>
      {
        isMedicair &&
        <div className={`col-1 position-relative fa-lg ${active ? '' : `${classes.iconArchiveColor}`}`}>
          <div
            onClick={toggleActive}
            className={`${classes.iconArchive}`}
            data-toggle="tooltip"
            title={active ? showArchivedPatientsTooltipText : hideArchivedPatientsTooltipText}
          >
            <i className={`fa fa-archive fa-lg`}></i>

          </div>
        </div>
      }

    </Fragment>
  );
}

export default PatientListHeader;
