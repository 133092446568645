import React from "react";

const Zip = () => {
  return (
    <svg width="52" height="52" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M31.6667 5H8.33333C6.48333 5 5 6.48333 5 8.33333V31.6667C5 32.5507 5.35119 33.3986 5.97631 34.0237C6.60143 34.6488 7.44928 35 8.33333 35H31.6667C32.5507 35 33.3986 34.6488 34.0237 34.0237C34.6488 33.3986 35 32.5507 35 31.6667V8.33333C35 6.48333 33.5 5 31.6667 5Z" fill="#FFB263" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M20 28.3333H23.3333V25H20V21.6667H23.3333V18.3333H20V15H23.3333V11.6667H20V8.33334H16.6667V11.6667H20V15H16.6667V18.3333H20V21.6667H16.6667V25H20V28.3333Z" fill="white" />
    </svg>
  );
};

export default Zip;
