import React, { useEffect, useRef, useState } from "react";
import style from "./List.module.sass";
import PropTypes from "prop-types";
import Preload from "../Preload/Preload";
import ListPagination from "./ListPagination/ListPagination";
import { callApi, getBaseURL } from "../../helpers";

function List(props) {
  const {
    baseQuery,
    renderItem,
    showPagination,
    onItemsLoaded,
    nothingFoundRender,
  } = props;
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoadingAjax, setIsLoadingAjax] = useState(false);
  const [totPages, setTotPages] = useState(0);
  const [nextUrl, setNextUrl] = useState("");
  const [prevUrl, setPrevUrl] = useState("");
  const [bShowPreload, setBShowPreload] = useState(false);
  const [translations, setTranslations] = useState(null);
  const timerPreload = useRef();
  let abortController = useRef();
  const signal = useRef();
  const startParams = useRef({});

  useEffect(() => {
    setStarParams();
  }, []);

  useEffect(() => {
    getData(baseQuery);
  }, [props.baseQuery]);

  const renderItems = () => {
    return items.map((item, index) => {
      return (
        <li key={`item-${index}-${item.id}`}>
          {renderItem ? renderItem({ ...item, ...translations }) : null}
        </li>
      );
    });
  };

  const resetAbortController = () => {
    abortController.current = new AbortController();
    signal.current = abortController.current.signal;
  };

  const onGetDataSuccess = (data) => {
    setTranslations(data.translations);
    setIsLoadingAjax(false);
    if (data.pagy) {
      const { next_url, page, prev_url, pages } = data.pagy;
      setNextUrl(next_url);
      setPrevUrl(prev_url);
      setPage(page);
      setTotPages(pages);
    }
    setItems(data.items);
    if (onItemsLoaded) {
      onItemsLoaded(data.items, data);
    }
  };

  const onGetDataFinally = () => {
    setBShowPreload(false);
    clearInterval(timerPreload.current);
  };

  const updateUrl = (query) => {
    const nextUrl = new URL(query);
    const params = nextUrl.search;
    const url = getBaseURL(window.location.href) + params;
    const title = document.getElementsByTagName("title")[0].innerHTML;
  };

  const setStarParams = () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.searchParams.forEach(function (value, key) {
      startParams.current[key] = value;
    });
  };

  const cleanQuery = (query) => {
    const APIFUllUrl = new URL(window.location.origin + query);
    // FIll with params when the page is loaded for the first time
    if (startParams.current) {
      for (const [key, value] of Object.entries(startParams.current)) {
        if (!APIFUllUrl.searchParams.get(key)) {
          APIFUllUrl.searchParams.set(key, value);
        }
      }
      startParams.current = null;
    }
    const APIParameters = APIFUllUrl.search;
    const queryUrl = getBaseURL(APIFUllUrl.toString()) + APIParameters;
    return queryUrl;
  };

  const getData = (query) => {
    query = cleanQuery(query);
    updateUrl(query);
    timerPreload.current = setTimeout(() => {
      setBShowPreload(true);
    }, 200);
    if (isLoadingAjax) {
      if (abortController.current) {
        abortController.current.abort();
      }
    }
    resetAbortController();
    setIsLoadingAjax(true);
    callApi(query, "GET", "json", onGetDataSuccess, null, onGetDataFinally, {
      signal: signal.current,
    });
  };

  const showPreload = () => {
    let preloadClass = `${style.ListPaginationLoading} ${bShowPreload ? style["ListPaginationLoading--show"] : ""
      }`;
    return (
      <div className={preloadClass}>
        <Preload />
      </div>
    );
  };

  const clickOnPrevPage = () => {
    getData(prevUrl);
  };

  const clickOnNextPage = () => {
    getData(nextUrl);
  };

  const showNotFound = () => {
    return isLoadingAjax ? null : nothingFoundRender ? (
      nothingFoundRender()
    ) : (
      <span>{translations?.pagy?.messages?.nothing_found}</span>
    );
  };

  const renderList = () => {
    return <ul>{renderItems()}</ul>;
  };

  return (
    <div
      className={`${style.List} ${isLoadingAjax ? style.ListIsLoading : ""}`} style={{ position: "relative" }}
    >
      {showPreload()}
      {items.length > 0 ? renderList() : null}
      {items.length == 0 ? showNotFound() : null}
      {(showPagination && items.length > 0) && (
        <ListPagination
          clickOnPrevPage={clickOnPrevPage}
          clickOnNextPage={clickOnNextPage}
          page={page}
          totPages={totPages}
          translations={translations?.pagy?.nav}
        />
      )}
    </div>
  );
}

List.propTypes = {
  showPagination: PropTypes.bool,
};

List.defaultProps = {
  showPagination: true,
};

export default List;
