import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./MenuItem.module.sass";
import { callApi } from "../../../helpers";

function MenuItem(props) {
  const {
    active,
    tooltip,
    url,
    id,
    stateChat,
    notitficationsCount,
    calendarURL,
    calendarRedirectURL,
    calendarData,
    disabled,
    hidden
  } = props;
  const rootRef = useRef();

  useEffect(() => {
    if (stateChat) {
      rootRef.current.dataset.state = stateChat;
    }
    if (id == "wave") {
      initWaveBtn();
    }
  }, []);

  const onClickWave = (e) => {
    e.preventDefault();
    Preload.show();
    const calendarDataJSON = JSON.parse(calendarData);
    callApi(
      calendarURL,
      "POST",
      "json",
      (data) => {
        window.location.href = calendarRedirectURL;
      },
      null,
      null,
      {
        body: JSON.stringify({ ...calendarDataJSON }),
      },
      {
        "Access-Control-Allow-Origin": "*",
      }
    );
  };

  const initWaveBtn = () => {
    rootRef.current.addEventListener("click", onClickWave.bind(this));
  };

  return (
    <li
      className={`auto-tooltip ${active ? style.active : ""} ${style.MenuItem} ${disabled ? "license-disabled-clickable" : ""} ${hidden ? "d-none" : ""}`}
      data-toggle="tooltip"
      data-placement="right"
      title={tooltip}
      id={id}
      ref={rootRef}
    >
      <a href={url}>{props.children}</a>
      {notitficationsCount > 0 && (
        <span className={`${style.notificationsNumber}`}>
          {notitficationsCount}
        </span>
      )}
    </li>
  );
}

MenuItem.propTypes = {};

MenuItem.defaultProps = {};

export default MenuItem;
