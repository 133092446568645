import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { callApi } from "../../../../helpers";

function SavedSequenceInputName(props) {
  const { placeholder, updateUrl } = props;
  const [value, setValue] = useState(props.value);
  const onChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
  };

  const onBlur = (e) => {
    const newValue = e.target.value;
    updateDb(newValue);
  };

  const updateDb = (newValue) => {
    callApi(updateUrl, "POST", "json", null, null, null, {
      body: JSON.stringify({ code: newValue })
    });
  };

  return (
    <input
      type={"text"}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      onBlur={onBlur}
    />
  );
}

SavedSequenceInputName.propTypes = {};

SavedSequenceInputName.defaultProps = {};

export default SavedSequenceInputName;
