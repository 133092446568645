import React, { Fragment, useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./RentalStatus.module.sass";

import icon_border_house_white from "./assets/icon_border_house_white.svg";
import icon_empty from "./assets/icon_empty.svg";
import btn_picker from "./assets/btn-picker.svg";
import icon_tkura_unconfirmed from "./assets/icon_tkura_unconfirmed.svg";
import icon_center_unconfirmed from "./assets/icon_center_unconfirmed.svg";
import { PatientBoxContext } from "../PatientBox.js";

function RentalStatus(props) {
  const {} = props;
  const rentalStatusValue = useContext(PatientBoxContext).rentalStatus;
  const {
    rentalStatus,
    tooltipHomeTitle,
    homeLink,
    emptyLink,
    tooltipNotValidHome,
    allSequencesLink,
    tooltipAllSequences,
    tooltipPatientToConfirm,
    patientToConfirmLink,
  } = rentalStatusValue;

  const renderRentalStatus = () => {
    switch (rentalStatus) {
      case "nothing":
        return (
          <div
            className={`auto-tooltip`}
            data-toggle="tooltip"
            title={tooltipHomeTitle}
          >
            <a href={homeLink} onClick={(e) => e.stopPropagation()}>
              <img src={icon_border_house_white} />
            </a>
          </div>
        );
      case "empty":
        return (
          <a
            href={emptyLink}
            onClick={(e) => e.stopPropagation()}
            className={"auto-tooltip ms-3"}
            data-toggle="tooltip"
            title={tooltipNotValidHome}
          >
            <img src={icon_empty} />
          </a>
        );
      case "tkura":
      case "center":
        return (
          <a
            href={allSequencesLink}
            onClick={(e) => e.stopPropagation()}
            className={"auto-tooltip ms-3"}
            data-toggle="tooltip"
            title={tooltipAllSequences}
          >
            <img src={btn_picker} />
          </a>
        );
      case "tkura-unconfirmed":
      case "not-yet-bought":
        return (
          <a
            href={patientToConfirmLink}
            onClick={(e) => e.stopPropagation()}
            className={"auto-tooltip ms-3"}
            data-toggle="tooltip"
            title={tooltipPatientToConfirm}
          >
            <img src={icon_tkura_unconfirmed} />
          </a>
        );
      case "center-unconfirmed":
        return (
          <Fragment>
            <a
              href={patientToConfirmLink}
              onClick={(e) => e.stopPropagation()}
              className={"auto-tooltip ms-3"}
              data-toggle="tooltip"
              title={tooltipPatientToConfirm}
            >
              <img src={icon_center_unconfirmed} />
            </a>

            <a
              href={allSequencesLink}
              onClick={(e) => e.stopPropagation()}
              className={"auto-tooltip ms-3"}
              data-toggle="tooltip"
              title={tooltipAllSequences}
            >
              <img src={btn_picker} />
            </a>
          </Fragment>
        );
    }
  };

  return <Fragment>{renderRentalStatus()}</Fragment>;
}

RentalStatus.propTypes = {};

RentalStatus.defaultProps = {};

export default RentalStatus;
