import { callApi } from "../../helpers";

const reducer = (state, action) => {
  const { districtIndex, groupIndex, exerciseIndex, value, type, property, duration, durationText, durationWarning, bulkActionsProperty, updatedExercise, updatedExercises } =
    action;
  let newState = JSON.parse(JSON.stringify(state));
  console.log('action', action);
  switch (type) {
    case "update_state":
      newState = JSON.parse(JSON.stringify(action.state));
      break;
    case "update_global_property":
      newState.bulkActions[bulkActionsProperty].selected = action.value;
      newState.sequence.duration = duration;
      newState.sequence.durationText = durationText;
      newState.sequence.durationWarning = durationWarning;
      newState.districts.map((district) => {
        district.groups.map((group) => {
          group.items.map((item) => {
            let updatedExercise = updatedExercises.find(obj => { return obj.id == item.id } )
            item[property] = value;
            item.time = updatedExercise.duration
            item.repetitions = updatedExercise.repetition
          });
        });
      });
      break;
    case "update_property":
      newState.districts[districtIndex].groups[groupIndex].items[exerciseIndex][
        property
      ] = value;
      newState.districts[districtIndex].groups[groupIndex].items[exerciseIndex][
        'time'
      ] = updatedExercise.duration;
      newState.districts[districtIndex].groups[groupIndex].items[exerciseIndex][
        'repetitions'
      ] = updatedExercise.repetition;
      newState.sequence.duration = duration;
      newState.sequence.durationText = durationText;
      newState.sequence.durationWarning = durationWarning;
      break;
  }
  state = { ...state, ...newState };
  return state;
};

const initialState = {
  districts: [],
  bulkActions: {},
  duration: 0,
  durationText: "",
  durationWarning: ""
};

function initState(values) {
  return {
    ...initialState,
    url: values.url,
  };
}

export { initState, reducer };
