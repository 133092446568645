import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { SettingsContext } from "../../Settings";
import styles from "./ReportNotifications.module.sass";

function ReportNotifications(props) {
  const { } = props;
  const value = useContext(SettingsContext);
  const report = value.sections.report;
  const [notificationDailyMail, setNotificationDailyMail] = useState(
    report.email.checked
  );
  const [noticeMessage, setNoticeMessage] = useState("");
  const [mailCadence, setMailCadence] = useState(report.day.selected);
  const [mailTime, setMailTime] = useState(report.hour.selected);

  const saveSetting = value.saveSetting;
  const translations = value.translations;

  const clickOnCheckbox = (e, setting) => {
    if (setting == "notification_daily_mail") {
      const newValue = !notificationDailyMail;
      setNoticeMessage("");
      setNotificationDailyMail(newValue);
      saveSetting(report.url, {
        body: JSON.stringify({
          data: {
            type: "notification_daily_mail",
            value: newValue ? "true" : "false",
          },
        }),
      });
    }
  };

  const onChangeSelect = (e, setting) => {
    updateValue(setting, e.target.value);
  };

  const onUpdateValue = (data) => {
    setNoticeMessage(data.text);
  };

  const updateValue = (type, value) => {
    saveSetting(
      report.urlPreference,
      {
        body: JSON.stringify({ data: { type, value } }),
      },
      onUpdateValue,
      "json"
    );
    if (type == "notification_mail_cadence") {
      setMailCadence(value);
    }
    if (type == "notification_mail_preferred_time") {
      setMailTime(value);
    }
  };

  return (
    <div>
      <h2>{translations.settings.notifications.report}</h2>
      <div>{translations.settings.notifications.send_email}</div>
      <br />
      <div>
        <input
          id="notification_daily_mail"
          type={"checkbox"}
          checked={notificationDailyMail}
          onChange={(e) => clickOnCheckbox(e, "notification_daily_mail")}
        />
        <label htmlFor={"notification_daily_mail"}>
          {translations.settings.mailreport.report}
          <span data-placement={"right"} title={"tooltip title"}>
            i
          </span>
        </label>
      </div>
      {notificationDailyMail && (
        <div className={`mt-1`}>
          <div> {translations.settings.notifications.when_explained}</div>
          <span>{translations.settings.notifications.every}</span>
          <span data-type="select">
            <select
              onChange={(e) => onChangeSelect(e, "notification_mail_cadence")}
              defaultValue={mailCadence}
            >
              <option value="day">
                {translations.settings.notifications.day}
              </option>
              {translations.days.map((day, index) => {
                return (
                  <option key={"day-" + day} value={index}>
                    {day}
                  </option>
                );
              })}
            </select>
          </span>
          <span>{translations.settings.notifications.at}</span>
          <span data-type="select">
            <select
              defaultValue={mailTime}
              onChange={(e) =>
                onChangeSelect(e, "notification_mail_preferred_time")
              }
            >
              {Array.apply(null, Array(24)).map((el, index) => {
                return (
                  <option key={"time-" + index} value={index + ""}>
                    {index}
                  </option>
                );
              })}
            </select>
          </span>
        </div>
      )}
      <div
        className={`${styles.noticeChange} ${noticeMessage && notificationDailyMail
            ? styles.noticeChangeActive
            : ""
          }`}
      >
        {noticeMessage}
      </div>
    </div>
  );
}

ReportNotifications.propTypes = {};

ReportNotifications.defaultProps = {
  notificationDailyMail: true,
  mailCadence: "",
  mailTime: "",
};

export default ReportNotifications;
