import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./FavouritePlans.module.sass";
import SavedSequence from "./SavedSequence/SavedSequence";
import List from "../List/List";
import FavouritePlansHeader from "./FavouritePlansHeader/FavouritePlansHeader";
import Notice from "../Notice/Notice";

function FavouritePlans(props) {
  const { addNewText, addNewURL, searchPlaceholder, noticeText, showNotice, disableCreateBtn } = props;
  const baseUrl = "/saved_sequences.json";
  const [queryUrl, setQueryUrl] = useState(baseUrl);

  const onChangeFilter = (search = "") => {
    let newQueryUrl = baseUrl + "?name=" + search;
    setQueryUrl(newQueryUrl);
  };

  const onRemoveItem = () => {
    let newQueryUrl = queryUrl;
    if (newQueryUrl.includes("?")) {
      newQueryUrl += "&random=" + Math.random();
    } else {
      newQueryUrl += "?random=" + Math.random();
    }
    setQueryUrl(newQueryUrl);
  };

  const renderItem = (item) => {
    return <SavedSequence {...item} onRemove={onRemoveItem} />;
  };

  const renderCreateNewPlanBtn = () => {
    return (
      <div className={`${style.createNewPlan}`}>
        <a href={addNewURL} className={`no-link ${disableCreateBtn ? "license-disabled" : ""}`}>
          <div className={`custom-height-similar mt-5 mb-5`}>
            <div className={`clickable ${style.button}`}>{addNewText}</div>
          </div>
        </a>
      </div>
    );
  };

  return (
    <div className={`${style.FavouritePlans}`}>
      <div className={`${style.filters}`}>
        <FavouritePlansHeader
          onChangeFilter={onChangeFilter}
          searchPlaceholder={searchPlaceholder}
        />
      </div>
      <Notice showNotice={showNotice} noticeText={noticeText} />
      {renderCreateNewPlanBtn()}
      <List baseQuery={queryUrl} renderItem={renderItem} />
    </div>
  );
}

FavouritePlans.propTypes = {};

FavouritePlans.defaultProps = {};

export default FavouritePlans;
