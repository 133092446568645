import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import style from "./Exercise.module.sass";
import RadiosBlock from "../../../BulkChangeActions/RadiosBlock/RadiosBlock";
import { CustomizationsPageContext } from "../../../CustomizationsPage";
import NameAndArrows from "./NameAndArrows/NameAndArrows";
import AddRemoveExercise from "./AddRemoveExercise/AddRemoveExercise";
import DeleteIcon from "./assets/cross_circle.svg";
import { callApi, camelize } from "../../../../../helpers";
function Exercise(props) {
  const {
    id,
    district,
    groupId,
    name,
    typology,
    sets,
    difficultyId,
    sideId,
    repetitions,
    time,
    urls,
    districtIndex,
    groupIndex,
    index,
    hasSide,
    firstExercise,
    lastExercise,
  } = props;
  const { state, dispatcher, refreshState } = useContext(
    CustomizationsPageContext
  );
  const [hide, setHide] = useState(false);
  const radioSideName = `side_${district}_${groupId}_${id}`;
  const radioDurationName = `duration_${district}_${groupId}_${id}`;
  const rootRef = useRef();

  const renderRepetitions = (repetitions) => {
    return (
      <Fragment>
        x<span>{repetitions}</span> {state.translations?.repetionMeasure}
      </Fragment>
    );
  };

  useEffect(() => {
    if (hide) {
      callApi(state.url, "GET", "json", (data) => {
        setTimeout(() => {
          dispatcher({
            type: "update_state",
            state: data,
          });
        }, 300);
      });
    }
  }, [hide]);

  const renderDuration = (duration) => {
    return (
      <Fragment>
        <span>{duration}</span>''
      </Fragment>
    );
  };

  const update_property = (url, property, value) => {
    callApi(url + "?" + property + "=" + value, "PATCH", "json", (data) => {
      const { duration, durationText, updatedExercise, durationWarning } = data;
      console.log(camelize(property));
      dispatcher({
        type: "update_property",
        value,
        districtIndex,
        groupIndex,
        exerciseIndex: index,
        property: camelize(property),
        updatedExercise: updatedExercise,
        duration,
        durationText,
        durationWarning
      });
    });
  };

  const onChangeSide = (value) => {
    update_property(urls.customization, "side_id", value);
  };

  const onChangeDuration = (value) => {
    update_property(urls.customization, "difficulty_id", value);
  };

  const onChangeSets = (value) => {
    update_property(urls.customization, "sets", value);
  };

  const onChangePosition = (direction) => {
    callApi(`${urls.move}?direction=${direction}`, "POST", "json", () => {
      refreshState(highlightElement);
    });
  };

  const highlightElement = () => {
    console.log(rootRef.current);
    rootRef.current.classList.add(style["Exercise--actived"]);
    setTimeout(() => {
      rootRef.current.classList.remove(style["Exercise--actived"]);
    }, 400);
  };

  const onDelete = () => {
    rootRef.current.style.height =
      rootRef.current.getBoundingClientRect().height + "px";
    callApi(urls.delete, "DELETE", "json", () => {
      setHide(true);
    });
  };

  const enableArrowUp = !firstExercise;
  const enableArrowDown = !lastExercise;

  return (
    <div
      className={`${style.Exercise} ${hide ? style.hide : ""} row`}
      ref={rootRef}
    >
      <div className={`col-lg-4`}>
        <NameAndArrows
          onChangePosition={onChangePosition}
          name={name}
          typology={typology}
          enableArrowUp={enableArrowUp}
          enableArrowDown={enableArrowDown}
          videoUrl={urls.video}
        />
      </div>
      <div className={`col-lg-1`}>
        <AddRemoveExercise onChangeSets={onChangeSets} sets={sets} />
      </div>
      <div className={`col-lg-2 py-3 text-center`}>
        {hasSide && (
          <RadiosBlock
            values={state.bulkActions["side"].values}
            selected={sideId}
            showTexts={false}
            inverted
            name={radioSideName}
            onChangeRadio={onChangeSide}
          />
        )}
      </div>
      <div className={`col-lg-1`}></div>
      <div className={`col-lg-2 py-3 text-center`}>
        <RadiosBlock
          values={state.bulkActions.difficulty.values}
          selected={difficultyId}
          showTexts={false}
          inverted
          name={radioDurationName}
          onChangeRadio={onChangeDuration}
        />
      </div>
      <div className={`col-lg-1 py-3 text-center text-lowercase`}>
        {typology?.code == "TYPE_BAL"
          ? renderDuration(time)
          : renderRepetitions(repetitions)}
      </div>
      <div className={`col-lg-1 py-3 text-center`}>
        <div onClick={onDelete}>
          <img src={DeleteIcon} />
        </div>
      </div>
    </div>
  );
}

Exercise.propTypes = {};

Exercise.defaultProps = {};

export default Exercise;
