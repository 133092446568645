import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./NotificationPreview.module.sass";
import { callApi, getTransitionEndEventName } from "../../../helpers";

function NotificationPreview(props) {
  const { onRemove, id, action, path, notificable, actor, shortDate, isMedicair, color } = props;
  const [visible, setVisible] = useState(true);
  const rootRef = useRef();
  const transitionEndFunction = useRef(null);

  useEffect(() => {
    addListener();
  }, []);

  const addListener = () => {
    transitionEndFunction.current = onItemHide.bind(this);
    rootRef.current.addEventListener(
      "transitionend",
      transitionEndFunction.current
    );
  };

  const removeListener = () => {
    rootRef.current.removeEventListener(
      "transitionend",
      transitionEndFunction.current
    );
  };

  const onDeleteSuccess = () => {
    onRemove();
  };

  const deleteItem = () => {
    callApi(path, "DELETE", "", onDeleteSuccess, null, null, {
      id,
    });
  };

  const onItemHide = () => {
    if (
      rootRef.current.classList.contains(style["NotificationPreview--hide"])
    ) {
      deleteItem();
      removeListener();
    }
  };

  const onClickDelete = (e) => {
    e.preventDefault();
    setVisible(false);
  };

  const notificationColorClass = () => {
    if (isMedicair) return `notification__text-color--${color}`

    return "notification__text-color--grey-blue"
  }

  let rootClass = `${style.NotificationPreview} py-3 ${visible ? "" : style["NotificationPreview--hide"]
    }`;

  return (
    <div className={rootClass} id={`notification-${id}`} key={id} ref={rootRef}>
      <div className={`row`}>
        <div
          className={`col-sm-3 ${style["notification__time"]} text-center ${style["notification__text-color--grey-blue"]}`}
        >
          <strong dangerouslySetInnerHTML={{ __html: shortDate }}></strong>
        </div>
        <div className={`col-sm-7`}>
          <div className={`${style[notificationColorClass()]}`}>
            <a href={actor.path}>
              <strong>{actor.name}</strong>
            </a>
          </div>
          <div className={`${style["notification__action"]}`}>
            <a href={notificable.path}>
              <strong>{action}</strong>
            </a>
          </div>
        </div>

        <div className={`col-sm-2`}>
          <button className={style.cancel} onClick={onClickDelete} />
        </div>
      </div>
    </div>
  );
}

NotificationPreview.propTypes = {};

NotificationPreview.defaultProps = {};

export default NotificationPreview;
