import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./VideoContainer.module.sass";
import { callApi } from "../../../helpers";

function VideoContainer(props) {
  const { translations, deleteUrl, url, name, updateData } = props;

  const onClickDeleteCall = () => {
    if (confirm(translations.deleteConfirm)) {
      callApi(deleteUrl, "DELETE", "json", (data) => {
        updateData();
      });
    }
  };

  return (
    <div className={`${style.VideoContainer}`}>
      {url ? (
        <video className={`rounded`} controls>
          <source src={url} type="video/mp4" />
          |Sorry, your browser doesn't support embedded videos.
        </video>
      ) : (
        <div className={`${style.Disclaimer} border rounded`}>
          {translations.videoDisclaimer}
        </div>
      )}
      <div className={` row mt-2`}>
        <div className={`col`}>
          <div className={`${style.NameText}`}>{name}</div>
        </div>
        <div className={`col-auto text-end position-relative`}>
          <div className={`${style.DeleteCall}`} onClick={onClickDeleteCall}>
            <i className={`fas fa-trash red-color pe-3`}></i>
          </div>
        </div>
      </div>
    </div>
  );
}

VideoContainer.propTypes = {};

VideoContainer.defaultProps = {};

export default VideoContainer;
