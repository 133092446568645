import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./Notice.module.sass";

function Notice(props) {
  const { noticeText, showNotice } = props;
  const [visible, setVisible] = useState(showNotice);

  console.log(props);

  const clickOnClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
    }, 10 * 1000);
  });

  return (
    <div className={`${style.Notice} ${visible ? "" : style["Notice--hide"]}`}>
      {noticeText}
      <span onClick={clickOnClose}></span>
    </div>
  );
}

Notice.propTypes = {};

Notice.defaultProps = {};

export default Notice;
