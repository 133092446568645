import React, { Fragment, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./PatientBox.module.sass";
import SliderProgressBar from "../DailySequence/Slider/SliderProgressBar";
import RentalStatus from "./RentalStatus/RentalStatus";
import increasingPositiveIcon from "./assets/andament_up_graph.svg";
import increasingNegativeIcon from "./assets/andament_down_graph.svg";
import alertRed from "./assets/alert_red.svg";
import alertYellow from "./assets/alert_yellow.svg";
function PatientBox(props) {
  const {
    toClick,
    linkToClick,
    patientName,
    patientId,
    patientLoginCode,
    patientEmail,
    showEmail,
    score,
    showProgressBar,
    increasingPositive,
    increasingNegative,
    rentalStatus,
    tooltipHomeTitle,
    homeLink,
    emptyLink,
    tooltipNotValidHome,
    allSequencesLink,
    tooltipAllSequences,
    tooltipPatientToConfirm,
    patientToConfirmLink,
    useRentals,
    hasAlerts,
    isMedicair,
    hasRedAlerts,
    hasYellowAlerts,
    rentalsDone,
    rentalsTodo,
    tooltipProgressBar
  } = props;

  const renderPatientNameLink = () => {
    return (
      <a href={linkToClick} className={`${style.patientName}`}>
        {patientName}
        {renderPatientId()}
      </a>
    );
  };

  const renderPatientName = () => {
    return (
      <span href={linkToClick} className={`${style.patientName}`}>
        {patientName}
        {renderPatientId()}
      </span>
    );
  };

  const renderPatientId = () => {
    return (
      (patientLoginCode || patientEmail) &&
      <span className={`${style.patientId} ms-2 px-1`}>
        {/* {patientId} */}
        {patientLoginCode}
        <span className={`patient-email ps-1`}>{realPatientEmail(patientEmail)}</span>
        {showEmail && <span className={`patient-email`}>{patientEmail}</span>} {/* TODO: probably we can safely remove this */}
      </span>

    );
  };

  const realPatientEmail = (email) => {
    if (email == null) return null;
    return email.includes('@nomail.no') ? null : email;
  }

  const renderProgressBar = () => {
    return (
      <div className="d-flex auto-tooltip" data-toggle="tooltip" title={tooltipProgressBar}>
        <div className={`${style.progress} ${isMedicair ? style.width80 : style.width100} mt-2`}>
          <div>
            <SliderProgressBar score={score} />
          </div>{" "}
        </div>
        {(isMedicair && score > 0) && <div className={`${style.rentalCount} mt-1`}>
          {rentalsDone}/{rentalsTodo}
        </div>}
      </div>
    );
  };

  const renderAlerts = () => {
    return hasAlerts ? (
      <div className={`${style.alert} mt-3`}>
        {hasYellowAlerts && <img src={alertYellow} />}
        {hasRedAlerts && <img src={alertRed} />}
      </div>
    ) : '';
  }

  const renderIncreasing = () => {
    return (
      <div className={`mt-3`}>
        {increasingPositive && <img src={increasingPositiveIcon} />}
        {increasingNegative && <img src={increasingNegativeIcon} />}
      </div>
    );
  }

  const goToPatient = () => {
    window.location.href = linkToClick;
  };

  return (
    <PatientBoxContext.Provider
      value={{
        rentalStatus: {
          rentalStatus,
          tooltipHomeTitle,
          homeLink,
          emptyLink,
          tooltipNotValidHome,
          allSequencesLink,
          tooltipAllSequences,
          tooltipPatientToConfirm,
          patientToConfirmLink,
        },
      }}
    >
      <div
        className={`${style.PatientBox}  px-4 ${toClick ? "clickable" : ""}`}
        onClick={toClick ? () => goToPatient() : null}
      >
        <div className={`col d-flex flex-column justify-content-center`}>
          <div className={`main-text`}>
            {toClick ? renderPatientNameLink() : renderPatientName()}
          </div>
          {showProgressBar && renderProgressBar()}
        </div>
        <div className={`col-sm-3 justify-content-end pt-4`}>
          {useRentals && <div className={`text-end`}>
            <RentalStatus />
            {isMedicair ? renderAlerts() : renderIncreasing()}
          </div>}
        </div>
      </div>
    </PatientBoxContext.Provider>
  );
}

PatientBox.propTypes = {
  // toClick: PropTypes.bool,
  // linkToClick: PropTypes.string,
  // patientName: PropTypes.string,
  // patientId: PropTypes.string,
  // patientEmail: PropTypes.string,
  // showEmail: PropTypes.bool,
  // score: PropTypes.number,
  // showProgressBar: PropTypes.bool,
  // increasingPositive: PropTypes.bool,
  // increasingNegative: PropTypes.bool,
  // rentalStatus: PropTypes.string,
  // tooltipHomeTitle: PropTypes.string,
  // homeLink: PropTypes.string,
};

PatientBox.defaultProps = {
  toClick: false,
};

export const PatientBoxContext = React.createContext({});
export default PatientBox;
