import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./Chat.module.sass";
import ChatList from "./ChatList/ChatList";
import ChatDiscussion from "./ChatDiscussion/ChatDiscussion";
import { callApi, useForceUpdate } from "../../helpers";
// const Faye = require("faye");

function Chat(props) {
  const { fayeAddressServerSide, fayeChannel, translations } = props;
  const chatRef = useRef(null);
  const chatListItemsRef = useRef({});
  const forceUpdate = useForceUpdate();

  console.log(props);

  useEffect(() => {
    let faye;
    faye = new Faye.Client(fayeAddressServerSide + "/faye");
    faye.subscribe(fayeChannel, onFayeMessage);
  }, []);

  const onFayeMessage = (data) => {
    const chatBlock = document.querySelector("#chat");
    if (chatBlock) {
      if (chatRef.current && data.chatId == chatRef?.current.id) {
        onClickItem(chatRef.current.id);
        callApi(`/chats/${data.chatId}/read`, "POST", "");
      } else {
        const chatListItemFunctions = chatListItemsRef.current[data.chatId];
        chatListItemFunctions.setReaded(false);
      }
    }
  };

  const onGetChat = (data) => {
    chatRef.current = data;
    forceUpdate();
  };

  const onClickItem = (id) => {
    callApi(`/chats/${id}.json`, "GET", "json", onGetChat);
  };

  return (
    <div className={`${style.Chat}`} id="chat">
      <div className={`grid-1-2`}>
        <ChatContext.Provider
          value={{ chatListItems: chatListItemsRef.current, translations }}
        >
          <div className={`${style.chatClickableList} left-list pb-5}`}>
            <ChatList
              onClickItem={onClickItem}
            />
          </div>
          <div>
            {chatRef.current && <ChatDiscussion {...chatRef.current} />}
          </div>
        </ChatContext.Provider>
      </div>
    </div>
  );
}

Chat.propTypes = {};

Chat.defaultProps = {};

export const ChatContext = React.createContext({});
export default Chat;
