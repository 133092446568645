import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./SavedSequence.module.sass";
import SavedSequenceInputName from "./SavedSequenceInputName/SavedSequenceInputName";
import Actions from "./Actions/Actions";

function SavedSequence(props) {
  const {
    shortSide,
    showTooltip,
    tooltipTitle,
    districtPresent,
    districtIcon,
    totExercises,
    singleSavedSequence,
    createdDate,
    placeholderText,
    updateNameUrl,
    duplicateUrl,
    deleteTextButton,
    editUrl,
    tooltipActions,
    id,
  } = props;
  const [visible, setVisible] = useState(true);
  const sideRef = useRef(null);

  const renderTooltipSide = () => {
    return (
      <div
        className={`${style.side} auto-tooltip side`}
        title={tooltipTitle}
        ref={sideRef}
      >
        {shortSide}
      </div>
    );
  };

  useEffect(() => {
    $(sideRef.current).tooltip();
  }, []);

  const onRemove = () => {
    setVisible(false);
    props.onRemove();
  };

  const onDuplicateEnd = (data) => {
    window.location.href = data.location;
  };

  let rootClass = `${style.SavedSequence} ${
    visible ? "" : style["SavedSequence--hide"]
  }`;

  return (
    <div className={rootClass} id={`sequence-${id}`}>
      <div className={`${style.name} position-relative`}>
        <SavedSequenceInputName
          value={props.name}
          placeholder={placeholderText}
          updateUrl={updateNameUrl}
        />
        {showTooltip && renderTooltipSide()}
      </div>
      <div className={style.exercisesCount}>
        {districtPresent == "true" && (
          <img src={require("./assets/" + districtIcon)} />
        )}

        <div className={"float-right"}>
          <span className={`${style.exerciseTitle} me-2`}>
            {singleSavedSequence.exercises}
          </span>
          <span className={style.count}>{totExercises}</span>
        </div>
      </div>
      <div className={`${style.createdAt} text-end`}>
        {singleSavedSequence.created}
        <span className={`float-right me-3 ms-2 ${style.createdAtDate}`}>
          {createdDate}
        </span>
      </div>
      <div className={`${style.commandsSequence}`}>
        <Actions
          duplicateUrl={duplicateUrl}
          deleteTextButton={deleteTextButton}
          editUrl={editUrl}
          tooltipActions={tooltipActions}
          onDeleteEnd={onRemove}
          onDuplicateEnd={onDuplicateEnd}
          deleteUrl={`/saved_sequences/${id}`}
        />
      </div>
    </div>
  );
}

SavedSequence.propTypes = {};

SavedSequence.defaultProps = {};

export default SavedSequence;
