export const maxData = (el1, el2) => {
  // Talvolta el2 può essere true invece che un array.
  if (el2 == true) {
    return [];
  }
  if (el1 && el2) {
    if (el1.length > el2.length) {
      return el1;
    } else {
      return el2;
    }
  } else {
    if (el1) return el1;
    if (el2) return el2;
  }
};

export const configChart = (data, borderColor1=undefined) => {
  return {
    type: "line",
    data: data,
    plugins: [
      {
        beforeRender: (chartData) => {
          // configurazione del plugin vedi https://www.chartjs.org/docs/2.9.4/developers/plugins.html
          graphicsSetup(chartData, borderColor1);
        },
      },
    ],
    options: graphicsOptions(),
  };
};

export const updateData = (index, enable, data, chart) => {
  if (chart.current) {
    chart.current.data.datasets[index].data = data.map(
      (val) => val * (enable ? 1 : 0)
    );
    chart.current.update();
  }
};

export const generateData = (label1, label2, labels, el1, el2) => {
  // Talvolta el2 può essere true invece che un array.
  if (el2 == true) {
    el2 = [];
  }
  return {
    labels: labels,
    datasets: [
      {
        label: label1,
        fill: false,
        data: el1,
        borderWidth: 2,
      },
      {
        label: label2,
        fill: false,
        data: el2,
        borderWidth: 2,
      },
    ],
  };
};

export const graphicsSetup = (chartData, borderColor) => {
  const chart = chartData.chart;
  const side = chart.canvas.dataset.side;
  const dataset = chartData.data.datasets[0];
  const model = chartData.data.datasets[0]._meta[Object.keys(dataset._meta)[0]]?.dataset._model;
  const model2 = chartData.data.datasets[1]._meta[Object.keys(dataset._meta)[0]]?.dataset._model;
  const customGradientFill1 = chart.ctx.createLinearGradient(0, 0, 0, chart.height);
  customGradientFill1.addColorStop(0, borderColor[0])
  customGradientFill1.addColorStop(1, borderColor[1])
  const gradientFill2 = chart.ctx.createLinearGradient(0, 0, 0, chart.height);
  gradientFill2.addColorStop(1, "rgba(105, 35, 130,1)");
  gradientFill2.addColorStop(0.61, "rgba(216, 106, 255, 1)");
  model.borderColor = customGradientFill1
  model2.borderColor = gradientFill2
};

export const graphicsOptions = () => {
  return {
    annotation: {
      drawTime: "afterDatasetsDraw",
      events: ["click"],
      annotations: [
        {
          type: "line",
          id: "90-line-1",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: 90,
          borderColor: "rgba(79,94,112,0.6)",
          borderWidth: 2,
        },
        {
          type: "line",
          id: "0-line-2",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: 0,
          borderColor: "rgba(79,94,112,0.6)",
          borderWidth: 2,
        },
        {
          type: "line",
          id: "90-line-3",
          mode: "horizontal",
          scaleID: "y-axis-0",
          value: -90,
          borderColor: "rgba(79,94,112,0.6)",
          borderWidth: 2,
        },
      ],
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    bezierCurve: false,
    elements: {
      borderWidth: 0,
      line: {
        tension: 0,
      },
      point: {
        radius: 0,
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
      callbacks: {
        label: function (t, d) {
          return (
            d.datasets[t.datasetIndex].label +
            " " +
            d.datasets[t.datasetIndex].data[t.index] +
            "°"
          );
        },
        title: function (t, d) {
          return "";
        },
      },
    },

    hover: {
      mode: "index",
      intersect: false,
    },

    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            display: false,
            beginAtZero: true, // minimum value will be 0.
          },
        },
      ],
      yAxes: [
        {
          labelling: {
            display: false,
          },
          gridLines: {
            display: true,
            drawBorder: false,
            tickWidth: 10,
            lineWidth: 1,
            tickLength: 5,
          },
          display: true,
          tickWidth: 10,
          ticks: {
            tickWidth: 10,
            tickLength: 5,
            // stepsize: 0.5,
            // OR //
            beginAtZero: true, // minimum value will be 0.
            display: true,
            callback: function (value, index, values) {
              if (value == 90 || value == 0 || value == -90) return value + "°";
              return "";
            },
            stepSize: 10,
            fixedStepSize: 10,
          },
        },
      ],
    },
  };
};
