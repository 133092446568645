import React, { useContext, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./CustomizationsPageHeader.module.sass";
import Button from "../../Button/Button";
import { CustomizationsPageContext } from "../CustomizationsPage";

function CustomizationsPageHeader(props) {
  const { } = props;
  const { state } = useContext(CustomizationsPageContext);

  return (
    <div className={`row mb-4 py-3 ${style.CustomizationsPageHeader}`}>
      <div className={`col-2`}>
        <Button kariBtnWhite href={state.urls?.buttons.back}>
          {state.translations?.buttons.back}
        </Button>
      </div>
      <div className={`col-8 text-center ${style.title}`}>{state.translations?.title}</div>

      <div className={`col-2`}>
        <div className={`text-end`}>
          <Button karyBtn kariBtnGreen href={state.urls?.buttons.next}>
            {state.translations?.buttons.next}
          </Button>
        </div>
      </div>
    </div>
  );
}

CustomizationsPageHeader.propTypes = {};

CustomizationsPageHeader.defaultProps = {};

export default CustomizationsPageHeader;
