import React, { useState } from "react";
import PropTypes from "prop-types";
import style from "./PatientPage.module.sass";
import SinglePatient from "../SinglePatient/SinglePatient";
import PatientAttachments from "./PatientAttachments/PatientAttachments";
import PatientProfile from "./PatientProfile/PatientProfile";
import PatientStatsChart from "../PatientStatsChart/PatientStatsChart";

function PatientPage(props) {
  const { patientPage } = props;
  const { patient, attachments, back, patientTable, stats, tabs } = patientPage;
  const [selectedTab, setSelectedTab] = useState("profile");

  function PatientSidebar(tab) {
    if (tab == "attachments") {
      return (
        <PatientAttachments {...attachments} />
      );
    } else if (tab == "profile") {
      return (
        <div>
          {
            stats.showChart &&
            <div className={`mb-5`}>
              <PatientStatsChart {...stats} />
            </div>
          }
          <PatientProfile patientTable={patientTable} />
        </div>
      );
    }
  }

  function tabStyle(tab) {
    if (tab == selectedTab) {
      return `${style.ProfileTab} ${style.ActiveTab}`;
    } else {
      return style.ProfileTab;
    }
  }

  return (
    <div className={`${style.PatientPage} row text-white`}>
      <div className={`col-md-4 position-relative`}>
        <div className={`mb-4 ${style.ProfileTabs}`}>
          <div
            className={tabStyle("profile")}
            onClick={() => setSelectedTab("profile")}>
            {tabs.profileLabel}
          </div>
          <div className={`${style.Separator}`}></div>
          <div
            className={tabStyle("attachments")}
            onClick={() => setSelectedTab("attachments")}>
            {tabs.attachmentsLabel}
          </div>
        </div>
        {PatientSidebar(selectedTab)}
      </div>
      <div className={`col-md-8 pt-4`}>
        <SinglePatient patient={patient} />
      </div>
    </div>
  );
}

PatientPage.propTypes = {};

PatientPage.defaultProps = {};

export default PatientPage;
