import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./PatientProfile.module.sass";
import { callApi } from "../../../helpers";

function PatientProfile(props) {
  const { patientTable } = props;

  const onSendResultSuccess = (target) => {
    target.setAttribute("disabled", true);
    target.classList.remove("clickable");
    target.innerHTML = "✓";
  };

  const onClickSendResult = (url, e) => {
    const target = e.target;
    e.preventDefault();
    e.stopPropagation();
    callApi(url, "POST", "", () => onSendResultSuccess(target));
  };

  const onClickArchivePatient = () => {
    const url = patientTable.active ? patientTable.archiveUrl : patientTable.unarchiveUrl;
    callApi(url, "POST", "", () => window.location.reload(false));
  };

  return (
    <div className={`${style.PatientProfile}`}>
      {patientTable.table.map((item) => {
        // do not show autogenarated email address
        if (item.key.toLowerCase() === "email" && item.value?.includes("@nomail.no"))
          return null;

        return item.key ? (
          <div className={`${style.property}`} key={item.key}>
            <div className={`${style.propertyTitle} ${item.keepTextCase ? style.KeepTextCase : ''}`}>{item.key}</div>
            <div className={`${style.value}`}>{item.value}</div>
          </div>
        ) : null;
      })}
      {/* NB: If unused, remove this part as well as used properties and components */}
      {/* <div className={`${style.property} ${style["property--no-line"]}`}>
        <button
          className={`clickable no-link`}
          onClick={(e) => onClickSendResult(patientTable.sendDataPathMonth, e)}
        >
          {patientTable.textDownloadMonth}
        </button>
      </div> */}
      <div className={`${style.property} ${style["property--no-line"]}`}>
        <button
          className={`clickable no-link`}
          onClick={(e) => onClickSendResult(patientTable.sendDataPathAll, e)}
        >
          {patientTable.textDownloadAll}
        </button>
      </div>
      {patientTable.pollResults &&
        <div className={`${style.property} ${style["property--no-line"]}`}>
          <a href={patientTable.pollResultsUrl} style={{ width: "50%" }}>
            <button className={`clickable no-link`} style={{ width: "100%" }}>
              {patientTable.pollResultsText}
            </button>
          </a>
        </div>
      }
      <div className={`${style.property} ${style["property--no-line"]}`}>
        <a href={patientTable.editUrl} style={{ width: "50%" }}>
          <button className={`clickable no-link`} style={{ width: "100%" }}>
            {patientTable.editText}
          </button>
        </a>
      </div>
      {
        patientTable.isMedicair &&
        <div className={`${style.property} ${style["property--no-line"]}`}>
          <button
            className={`clickable no-link ${style["archiveButton"]}`}
            onClick={(e) => onClickArchivePatient()}
          >
            <i className={`fa fa-archive me-2`}></i>
            {patientTable.active ? patientTable.archivePatientButtonText : patientTable.unarchivePatientButtonText}
          </button>
        </div>
      }
    </div>
  );
}

PatientProfile.propTypes = {};

PatientProfile.defaultProps = {};

export default PatientProfile;
