import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./Settings.module.sass";
import NotificationSetting from "./NotificationSetting/NotificationSetting";
import { callApi } from "../../helpers";

function Settings(props) {
  const { appVersion, translations, sections } = props;
  const [currentSetting, setCurrentSetting] = useState(0);
  const rootRef = useRef();

  console.log(sections);

  if (sections.languages.value == "en-GB") {
    sections.languages.value = "en-US";
  }

  useEffect(() => {
    rootRef.current
      .querySelectorAll("li")
    [currentSetting].classList.add(style.active);
  });

  const onClickSetting = (index) => {
    setCurrentSetting(index);
  };

  const renderAppVersion = () => {
    return <div className={`${style.appVersion}`}>{appVersion}</div>;
  };

  const onSaveSettingsSuccess = (data, onSuccess = null) => {
    if (onSuccess) {
      onSuccess(data);
    }
  };

  const saveSetting = (url, params, onSuccess = null, respType = "") => {
    callApi(
      url,
      "POST",
      respType,
      (data) => onSaveSettingsSuccess(data, onSuccess),
      null,
      null,
      {
        ...params,
      }
    );
  };

  return (
    <div className={`${style.Settings} grid-1-2`} ref={rootRef}>
      <div className={`${style.SettingsList}`}>
        <ul>
          <li className={`clickable`} onClick={() => onClickSetting(0)}>
            {translations.settings.general.label}
          </li>
        </ul>
      </div>
      <div className={`${style.SettingsDetailContainer}`}>
        <SettingsContext.Provider
          value={{ saveSetting, translations, sections }}
        >
          <div>
            <NotificationSetting />
          </div>
        </SettingsContext.Provider>
      </div>
      {renderAppVersion()}
    </div>
  );
}

Settings.propTypes = {};

Settings.defaultProps = {};

export const SettingsContext = React.createContext({});
export default Settings;
