import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./BookNewCall.module.sass";
import {
  callApi,
  callApiASync,
  convertToHumanDateFormat,
  useForceUpdate,
} from "../../../helpers";
import Select from "../../Select/Select";

function BookNewCall(props) {
  const {
    translations,
    lang,
    editCall,
    checkAvailabilityURL,
    url,
    newCallURL,
  } = props;

  const [submitEnabled, setSubmitEnabled] = useState(false);
  const [patient, setPatient] = useState(null);
  const [date, setDate] = useState("");
  const [patients, setPatients] = useState([]);
  const dataPickerRef = useRef();
  const locale = lang == "en-US" ? "en-US" : "it";

  const onBlurDataPicker = (e) => {
    if (e.target.value) {
      setDate(e.target.value);
      setSubmitEnabled(checkCallFree(e.target.value));
    }
  };

  const checkCallFree = async (date) => {
    const result = await callApiASync(checkAvailabilityURL, "POST", "json", '','','', {
      body: JSON.stringify({
        scheduled_date: date,
      }),
    });
    return result.text == "T_Avaiable";
  };

  const resetForm = () => {
    setSubmitEnabled(false);
    setDate("");
    if (patients.length > 0) {
      setPatient(patients[0][0]);
    }
  };

  const onClickClose = () => {
    resetForm();
    props.onClickClose();
  };

  const onChangePatient = (value) => {
    setPatient(value);
  };

  const initDatapicker = () => {
    $(dataPickerRef.current).datetimepicker({
      format: locale == "it" ? "DD/MM/YYYY HH:mm" : "MM/DD/YYYY HH:mm",
      locale,
      icons: {
        time: "far fa-clock",
      },
      minDate: new Date(),
    });
  };

  const today = () => {
    return `${new Date().toISOString().split('T')[0]}T00:00`
  }

  useEffect(() => {
    if (editCall) {
      setPatient(editCall.patient_id);
      setPatients(editCall.patients);
      const milliseconds = editCall.scheduledDate * 1000;
      const humanDateFormat = convertToHumanDateFormat(milliseconds, locale);
      setDate(humanDateFormat);
    } else {
      const humanDateFormat = convertToHumanDateFormat(null, locale);
      setDate(humanDateFormat);
    }
  }, [editCall]);

  useEffect(() => {
    initDatapicker();
    callApi(url, "GET", "json", (data) => {
      setPatients(data.patients);
      setPatient(data.patients[0][0]);
    });
  }, []);

  let patientsOptions = patients
    ? patients.map((patient) => {
        return {
          value: patient[0],
          text: patient[1],
        };
      })
    : [];

  if (editCall) {
    patientsOptions = editCall.patients.map((patient) => {
      return {
        value: patient[0],
        text: patient[1],
      };
    });
  }

  const onClickCreate = () => {
    if (editCall) {
      props.onUpdateCall(date, patient, editCall.updateUrl, locale);
    } else {
      props.onCreateNewCall(date, patient, locale);
    }
    resetForm();
  };

  return (
    <div className={`${style.BookNewCall}`}>
      <div className={`${style.horizontal}`}>
        <div className={`${style.halfWidth}`}>
          <label>{translations.choose}</label>
          <div className={`${style.selectCr}`}>
            {patient && (
              <Select
                options={patientsOptions}
                name={"option_patient"}
                selected={patient}
                onChange={onChangePatient}
              />
            )}
          </div>
        </div>
        <div className={`${style.halfWidth}`}>
          <label>{translations.when}</label>
          <div
            id="datetimepicker"
            ref={dataPickerRef}
            className={`${style.inputGroup} input-group date`}
            data-target-input="nearest"
          >
            {/* <input
              type="text"
              data-target="#datetimepicker"
              name="scheduled_date"
              className={`${style.formControl} form-control datetimepicker-input`}
              onBlur={onBlurDataPicker}
              defaultValue={date}
            /> */}
            {/* <div
              className={`${style.inputGroupAppend} input-group-append`}
              data-target="#datetimepicker"
              data-toggle="datetimepicker"
            >
            </div> */}
            <input
              name="scheduled_date"
              className="form-control"
              type= "datetime-local"
              min={today()}
              onBlur={onBlurDataPicker}/>
            {/* <div className={`${style.inputGroupText} input-group-text`}>
              <i className={`fa fa-calendar`} />
            </div> */}
          </div>
        </div>
      </div>

      <div className={`${style.horizontal} ${style.unavaiability}`}>
        <div />
        <div>
          <div className={`${style.error}`}>{translations.unavaiability}</div>
        </div>
      </div>
      <div className={`${style.horizontal}`}>
        <div />
        <div>
          <div
            className={`${style.btnWiredWhite} btn btn-wired-white`}
            onClick={onClickClose}
          >
            {translations.cancel}
          </div>
          <div
            className={`${style.btnSubmit} ${
              submitEnabled || editCall ? "" : style.btnSubmitDisable
            } btn`}
            onClick={onClickCreate}
          >
            {editCall ? translations.update : translations.create}
          </div>
        </div>
      </div>
    </div>
  );
}

BookNewCall.propTypes = {};

BookNewCall.defaultProps = {};

export default BookNewCall;
