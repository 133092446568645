import React, { useState } from "react";
import ExerciseSequenceChartEffort from '../ExerciseSequence/ExerciseSequenceChart/ExerciseSequenceChartEffort'
import ExerciseSequenceChartPulseOximeter from '../ExerciseSequence/ExerciseSequenceChart/ExerciseSequenceChartPulseOximeter'
import ExerciseResult from "../ExerciseResult/ExerciseResult";
import SliderDetails from "./SliderDetails";
import classes from "./Slider.module.sass";
import ExerciseFooterDownload from "../ExerciseFooterDownload/ExerciseFooterDownload";
import { callApi } from "../../../helpers";

function Slider(props) {
  const {
    colors,
    isMedicair,
    leftShortText,
    medicairData,
    path,
    rightShortText,
    type
  } = props;

  // close, loading, open, error
  const [statusSlider, setStatusSlider] = useState("close");
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  async function submitForm() {
    return callApi(path, "GET", "json", (data) => {
      setData(data);
      setStatusSlider("open");
    });
  }

  async function toggleDetails() {
    if (statusSlider == "close") {
      setStatusSlider("loading");
      try {
        await submitForm();
      } catch (err) {
        setStatusSlider("error");
        setError(err);
      }
    } else {
      setStatusSlider("close");
      setData(null);
    }
  }

  const currentCssClass = () => {
    if (statusSlider == "open") {
      return `${classes.bgGradient} ${classes.bgGradientOpen}`;
    } else {
      return `${classes.bgGradient} ${classes.bgGradientClose}`;
    }
  };

  const renderExercisesResults = () => {
    return data.exercises_results.map((el) => {
      return (
        // TODO fai cose qua
        <ExerciseResult
          {...el}
          textColor={colors.textColor}
          rightShortText={rightShortText}
          leftShortText={leftShortText}
          key={el.index}
          type={type}
        />
      );
    });
  };

  const renderExerciseResultsMedicair = () => {
    return data.exercises_results.map((el) => {
      const effortData = data?.exercises_results[0].dataForEffortChart[0].results[0].samples[0]
      return (
        <div>
          {effortData.length != 0 && <ExerciseSequenceChartEffort
            title={medicairData.chartTitleEffort}
            effortData={effortData}
          />}
          <ExerciseSequenceChartPulseOximeter
            title={medicairData.chartTitlePulseOximeter}
            heartRateData={data?.exercises_results[0].dataForPulseOximeterChart[0].results[0].samples[0]}
            saturationData={data?.exercises_results[0].dataForPulseOximeterChart[1].results[0].samples[0]}
            heartRateLabel={data?.exercises_results[0].dataForPulseOximeterChart[0].title}
            saturationLabel={data?.exercises_results[0].dataForPulseOximeterChart[1].title}
            medicairData={medicairData}
          />
        </div>
      );
    });
  }

  return (
    <div>
      <div
        className={currentCssClass()}
        onClick={!medicairData?.isOutdoor ? toggleDetails : undefined}
        style={{ cursor: "pointer" }}
      >
        <SliderDetails {...props} statusSlider={statusSlider} />
      </div>
      {data?.exercises_results && (isMedicair && renderExerciseResultsMedicair() || renderExercisesResults())}
      {data?.footer && <ExerciseFooterDownload {...data?.footer} textColor={colors.textColor} />}
    </div>
  );
}

export default Slider;
