import React from "react";
import classes from './SliderDoneAndTodo.module.sass'
function SliderDoneAndTodoNew(props){
  const done = props.done
  const todo = props.todo
  const exercisesLabel = props.exercisesLabel
  return (
    <div className="col-lg-3 border-left d-flex align-items-center">

      <div className={classes.first_data}>{done}</div>
      <div className="text-start">
        <div className={classes.second_data}> / {todo}</div>
        <div className={classes.new_label}>{exercisesLabel}</div>
      </div>

    </div>
    )
}

export default SliderDoneAndTodoNew