import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import style from "./ProfessionalPage.module.sass";
import Patients from "../Patients/Patients";
import PatientStatsChart from "../PatientStatsChart/PatientStatsChart";
import NotificationsBoxPreview from "../NotificationsBoxPreview/NotificationsBoxPreview";

function ProfessionalPage(props) {
  const { firstAccess, patients, stats, createPatient, notifications } = props;

  return (
    <div className={`${style.ProfessionalPage}`}>
      <div className={`row text-white`}>
        <div className={`col-sm-4`}>
          <div className={`mb-5 pt-4`}>
            <a className={createPatient.disabled ? "license-disabled" : ""} href={createPatient.url}>
              <div className={`btn btn-wired-white`}>{createPatient.text}</div>
            </a>
            {stats.showChart && <PatientStatsChart {...stats} />}
          </div>
          <NotificationsBoxPreview {...notifications} />
        </div>
        <div className={`col-sm-8`}>
          <Patients {...patients} firstAccess={firstAccess} isMedicair={stats.isMedicair} />
        </div>
      </div>
    </div>
  );
}

ProfessionalPage.propTypes = {};

ProfessionalPage.defaultProps = {};

export default ProfessionalPage;
