import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import style from "./Notification.module.sass";
import TrashHat from "./assets/trash_hat.svg";
import TrashBottom from "./assets/trash_bottom.svg";
import { callApi } from "../../../helpers";

function Notification(props) {
  const { path, id, statusRead, date, action, notificable, onRemove, actor, isMedicair, color } =
    props;
  const trashRef = useRef();
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    $(trashRef.current).tooltip();
  }, []);

  const onDeleteSuccess = () => {
    setVisible(false);
    onRemove();
  };

  const onClickDelete = (e) => {
    e.preventDefault();
    e.stopPropagation();
    callApi(path, "DELETE", "", onDeleteSuccess, null, null, {
      id,
    });
  };

  let rootClass = `${style.Notification} clickable ${visible ? "" : style["Notification--hide"]
    }`;

  const onClickMainblock = (e) => {
    window.location.href = notificable.path;
  };

  const onClickName = (e) => {
    e.preventDefault();
    e.stopPropagation();
    window.location.href = actor.path;
  };

  const notificationColorClass = () => {
    if (isMedicair) return `notification__text-color--${color}`

    return "notification__text-color--grey-blue"
  }

  return (
    <div
      className={rootClass}
      data-notification-id={id}
      data-notification-status-read={statusRead}
      onClick={onClickMainblock}
    >
      <div className={`${style.date}`}>{date}</div>
      <div className={`${style.corpus}`}>
        <div onClick={onClickName}>
          <div className={`${style.name} ${style[notificationColorClass()]}`}>{actor.name}</div>
        </div>
        <div className={`${style.mainText}`}>{action}</div>
      </div>
      <a
        onClick={onClickDelete}
        href={"#"}
        className={`${style.trash}`}
        data-placement={"left"}
        title={props.delete}
        ref={trashRef}
      >
        <img src={TrashHat} alt="" className={`${style.hat}`} />
        <img src={TrashBottom} alt="" className={`${style.bottom}`} />
      </a>
    </div>
  );
}

Notification.propTypes = {};

Notification.defaultProps = {};

export default Notification;
