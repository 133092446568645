import React from "react";
import classes from './SliderDoneAndTodo.module.sass'
function SliderDoneAndTodo(props){
  const done = props.done
  const todo = props.todo
  const exercisesLabel = props.exercisesLabel
  return (
    <div className="col border-left">
      <div className={classes.data}>{done} / {todo}</div>
      <div className={classes.label}>{exercisesLabel}</div>
    </div>)
}

export default SliderDoneAndTodo