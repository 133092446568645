import React from "react";

const AddPatient = (props) => {
  return (
    <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 9.875C11.33 9.875 6 11.1219 6 13.625V15.5H22V13.625C22 11.1219 16.67 9.875 14 9.875ZM5 6.125V3.3125H3V6.125H0V8H3V10.8125H5V8H8V6.125H5ZM14 8C15.0609 8 16.0783 7.60491 16.8284 6.90165C17.5786 6.19839 18 5.24456 18 4.25C18 3.25544 17.5786 2.30161 16.8284 1.59835C16.0783 0.895088 15.0609 0.5 14 0.5C12.9391 0.5 11.9217 0.895088 11.1716 1.59835C10.4214 2.30161 10 3.25544 10 4.25C10 5.24456 10.4214 6.19839 11.1716 6.90165C11.9217 7.60491 12.9391 8 14 8Z"
          fill="currentColor"
      />
    </svg>
  );
};

export default AddPatient;
