import React from "react";
import classes from './SliderDuration.module.sass'

function SliderDuration(props) {
  const {
    realDurationLabel,
    realDuration,
    estimateLabel,
    estimate,
    seperator
  } = props

  return (
    <div className="row text-center border-start border-end">
      <div className="d-flex justify-content-center w-100">
        <div>
          <div className={classes.label}>{realDurationLabel}</div>
          <div className={classes.data}>{(realDuration / 60).toFixed(0)} min</div>
        </div>
        <div className="mx-4">
          <span className={classes.seperator}>{seperator}</span>
        </div>
        <div>
          <div className={classes.label}>{estimateLabel}</div>
          <div className={classes.data}>{(estimate / 60).toFixed(0)} min</div>
        </div>
      </div>
    </div>
  )
}

export default SliderDuration