import Modal from "../Modal/Modal";
import React, { useEffect, useState, version } from "react";
import styles from "./ModalUpdates.module.sass";
import VersionItem from "./VersionItem/VersionItem";
import Preload from "../Preload/Preload";
import { callApi } from "../../helpers";

const ModalUpdates = (props) => {
  const [versions, setVersions] = useState([]);
  const [error, setError] = useState([]);
  const GET_VERSIONS_URL = "/software_updates";

  const onGetVersionSuccess = (data) => {
    setVersions(data.versions);
    setError("");
  };

  const onGetVersionsError = (e) => {
    setVersions([]);
    setError("Comunication error: " + error);
  };

  const getVersions = () => {
    callApi(
      GET_VERSIONS_URL,
      "GET",
      "json",
      onGetVersionSuccess,
      onGetVersionsError
    );
  };

  const onModalOpen = () => {
    getVersions();
  };

  function showPreload() {
    if (versions.length > 0 || error.length > 0) {
      return 0;
    } else {
      return 1;
    }
  }

  return (
    <Modal {...props} background={false} onModalOpen={onModalOpen}>
      <div className={styles.modalUpdates}>
        <div className={styles.preload} style={{ opacity: showPreload() }}>
          <Preload height={"90%"} />
        </div>
        {error.length > 0 && <p className="text-white">{error}</p>}
        <ul style={{ opacity: versions.length > 0 ? 1 : 0 }}>
          {versions.map((version) => {
            return (
              <li key={version.version}>
                <VersionItem version={version} />
              </li>
            );
          })}
        </ul>
      </div>
    </Modal>
  );
};

export { ModalUpdates as default };
